import { useMemo } from 'react';
import { FUEL_ID_TO_POI_VALUE } from '../../common/constants';
import { isValidFormattedPrice } from '../utils/prices';
import useDiscountedPrice from './useDiscountedPrice';

export default function useFormattedStationPrices(priceUnit, fuelType, offers, prices) {
  const fuel = useMemo(
    () => prices?.find(price => price.fuelProduct === FUEL_ID_TO_POI_VALUE[fuelType]),
    [fuelType, prices],
  );

  const { cash: cashPriceReport, credit: creditPriceReport } = fuel || {};

  // TODO: Why do we rebuild these objects when we don't change any of its original properties?
  const formattedCashPriceReport = useMemo(() => cashPriceReport && {
    price: cashPriceReport.price,
    formattedPrice: cashPriceReport.formattedPrice,
    postedTime: cashPriceReport.postedTime,
    reportedBy: cashPriceReport.nickname,
  }, [cashPriceReport]);

  // this should never be undefined so we at least always show `---` for a price
  const formattedCreditPriceReport = useMemo(() => ({
    price: creditPriceReport?.price,
    formattedPrice: creditPriceReport?.formattedPrice,
    postedTime: creditPriceReport?.postedTime,
    reportedBy: creditPriceReport?.nickname,
  }), [creditPriceReport]);

  const discountedPrice = useDiscountedPrice(offers, creditPriceReport?.price, priceUnit, fuelType);

  return {
    discountedPrice,
    cashPriceReport: formattedCashPriceReport,
    creditPriceReport: formattedCreditPriceReport,
    hasActiveCashPrice: isValidFormattedPrice(cashPriceReport?.formattedPrice),
  };
}
