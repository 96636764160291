import { canonicalizeString } from '../../lib/utils';

const auStates = [
  { name: 'Australian Capital Territory', abbr: 'ACT' },
  { name: 'New South Wales', abbr: 'NSW' },
  { name: 'Northern Territory', abbr: 'NT' },
  { name: 'Queensland', abbr: 'QLD' },
  { name: 'South Australia', abbr: 'SA' },
  { name: 'Tasmania', abbr: 'TAS' },
  { name: 'Victoria', abbr: 'VIC' },
  { name: 'Western Australia', abbr: 'WA' },
];

export default auStates.map(region => ({
  ...region,
  canonical: canonicalizeString(region.name),
  countryCode: 'AU',
}));
