import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.css';

const cx = classnames.bind(styles);

function Breadcrumb({ active, children, className, linkTo, showSeparator, ...rest }) {
  const Element = active ? Text : Link;
  // We have a regular breaking space so that it stays up with chevron
  // instead of breaking down with the children
  return (
    <Text bold className={cx('breadcrumb', className)} {...rest}>
      {showSeparator && (
        <FontAwesomeIcon icon={faChevronRight} />
      )}
      {' '}
      <Element to={active ? undefined : linkTo} className={cx('link', { active })}>
        {children}
      </Element>
    </Text>
  );
}

Breadcrumb.propTypes = {
  /* Whether or not this breadcrumb is the active page */
  active: PropTypes.bool,
  /* The children of the breadcrumb to display */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  /* Where the breadcrumb links to */
  linkTo: PropTypes.string,
  /* Whether or not this breadcrumb shows the chevron separator before its children */
  showSeparator: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  active: false,
  className: undefined,
  linkTo: undefined,
  showSeparator: true,
};

export default Breadcrumb;
