import usStates from '../../common/constants/usStates';
import caProvinces from '../../common/constants/caProvinces';
import { canonicalizeString } from '.';
import auStates from '../../common/constants/auStates';

export default function getRegionByName(regionName) {
  const allRegions = [...usStates, ...caProvinces, ...auStates];
  const canonical = canonicalizeString(regionName);
  return allRegions.find(r => canonical === r.canonical);
}
