import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { Link, Text, Tooltip } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import usePageContext from '../../../lib/hooks/usePageContext';
import { ANALYTICS_EVENTS } from '../../constants';
import styles from './DealAlertHighlight.module.css';

const cx = classnames.bind(styles);

export default function DealAlertHighlight({ offer }) {
  const analytics = useAnalytics();
  const { pageName } = usePageContext();

  const handlePayLandingClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.PAY_DETAILS_CLICKED,
      attributes: {
        'Screen Name': pageName,
        Section: 'Deal_Alert_Tooltip',
      },
    });
  }, [analytics, pageName]);

  const handleGasBackHelpClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.GASBACK_HELP_CLICKED,
      attributes: {
        'Screen Name': pageName,
        Section: 'Deal_Alert_Tooltip',
      },
    });
  }, [analytics, pageName]);

  return (
    <Text as="div" centered className={cx('highlight')}>
      <Text bold>{offer.discounts.length > 1 ? offer.highlight : offer.discounts[0].highlight}</Text>
      <Tooltip
        bordered={false}
        content={(
          <div className={cx('tooltipContent')}>
            <Text bold size="lg">Activate Deals, Get GasBack!</Text>
            <Text as="p">
              You don&apos;t need our <Link onClick={handlePayLandingClick} to="/pay">Pay with GasBuddy</Link> card to activate deals.
            </Text>
            <Text as="p">
              Instead of a direct discount at the pump with our card, the difference between the deal amount and the price you pay at the station is given back to you in the form of <Link onClick={handleGasBackHelpClick} to="https://help.gasbuddy.com/hc/en-us/articles/360021433893-Help-with-GasBack#h_757724db-01e8-446f-9376-87d1518cbc1a">GasBack</Link>.
            </Text>
          </div>
        )}
        icon={faQuestionCircle}
      />
    </Text>
  );
}

DealAlertHighlight.propTypes = {
  offer: PropTypes.shape({
    discounts: PropTypes.arrayOf(PropTypes.shape({
      highlight: PropTypes.string,
    })),
    highlight: PropTypes.string,
  }).isRequired,
};
