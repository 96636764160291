import { Modal } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import { noop } from '../../../lib/utils';
import BadgeImage from '../BadgeImage';

export default function BadgeWithModal({
  children,
  imageUrl,
  onClick,
  onModalOpen,
  title,
}) {
  return (
    <Modal
      content={children}
      size="sm"
      onOpen={onModalOpen}
    >
      {({ open }) => (
        <BadgeImage
          src={imageUrl}
          alt=" "
          clickable
          onClick={(e) => {
            onClick(e);
            open(e);
          }}
          title={title}
        />
      )}
    </Modal>
  );
}

BadgeWithModal.propTypes = {
  /** Passing a function will allow a caller to utilize the modal `close` function */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onModalOpen: PropTypes.func,
  title: PropTypes.string.isRequired,
};

BadgeWithModal.defaultProps = {
  children: [],
  onClick: noop,
  onModalOpen: undefined,
};
