import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { ANALYTICS_EVENTS } from '../../common/constants';
import useAnalytics from './useAnalytics';

export const GET_ACTIVE_DEAL_ALERT = gql`
  query GetActiveDealAlert {
    activeDealAlert {
      claimedAt
      offerId
      station {
        id
        name
      }
      submitBy
      transactBy
    }
  }
`;

export const UPLOAD_RECEIPT = gql`
  mutation UploadReceipt($offerId: String!, $receipt: Upload!) {
    uploadReceipt(offerId: $offerId, receipt: $receipt) {
      success
    }
  }
`;

export default function useDealAlert() {
  const analytics = useAnalytics();
  const { data } = useQuery(GET_ACTIVE_DEAL_ALERT);
  const dealAlert = data?.activeDealAlert;

  const [uploadReceipt, { data: uploadData, loading: uploading }] = useMutation(UPLOAD_RECEIPT, {
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'activeDealAlert' });
      cache.gc();
    },
    onCompleted: () => {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.DEAL_ALERT_RECEIPT_UPLOADED,
      });
    },
  });

  return {
    dealAlert,
    didUploadReceipt: uploadData?.uploadReceipt.success,
    isUploading: uploading,
    uploadReceipt,
  };
}
