import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Ad } from '../Ad';
import { AD_SLOTS } from '../../constants';

export default function RegionAds({
  topAdName,
  middleAdName,
  bottomAdName,
}) {
  // Get the target ad slots
  const topAd = AD_SLOTS.find(ad => ad.slotName === topAdName);
  const middleAd = AD_SLOTS.find(ad => ad.slotName === middleAdName);
  const bottomAd = AD_SLOTS.find(ad => ad.slotName === bottomAdName);

  // Replace ad slot names with dynamic name provided from consumer
  const ads = [topAd, middleAd, bottomAd];

  return ads.map(({ slotID, slotName, sizes, sizeMapping }) => (
    <Fragment key={slotID}>
      <Ad
        collapseIfEmpty
        id={slotID}
        path={slotName}
        sizeMapping={sizeMapping}
        sizes={sizes}
      />
      <br />
    </Fragment>
  ));
}

RegionAds.propTypes = {
  topAdName: PropTypes.string,
  middleAdName: PropTypes.string,
  bottomAdName: PropTypes.string,
};

RegionAds.defaultProps = {
  topAdName: 'GasBuddy_State_160x600_1',
  middleAdName: 'GasBuddy_State_160x600_2',
  bottomAdName: 'GasBuddy_State_160x600_3',
};
