import { Button, Container, ExpandableContainer, Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWindowSize } from '@gasbuddy/react-hooks';
import { Link } from 'react-router-dom';
import AmenityPropType from '../../prop-types/amenity';
import ReviewPropType from '../../prop-types/review';
import StationsMapContext from '../../context/selected-station-map';
import AmenitiesList from '../AmenitiesList';
import ReviewItem from '../ReviewItem';
import styles from './VerboseStationInfo.module.css';

const cx = classnames.bind(styles);

export default function VerboseStationInfo({ amenities, reviews, stationId }) {
  const selectedStationId = useContext(StationsMapContext);
  const { mobile } = useWindowSize();
  const expandedValue = !mobile && selectedStationId === stationId;

  return (
    <ExpandableContainer expanded={expandedValue}>
      <Container fluid>
        <Header as="h3" className={cx('dataLabel')} color="midnight">Amenities</Header>
        <AmenitiesList amenities={amenities} expanded={false} />
        <br />
        <Header as="h3" className={cx('dataLabel')} color="midnight">Reviews</Header>
        {reviews?.length ? reviews.map(review => (
          <ReviewItem
            key={review.reviewId}
            review={review}
          />
        )) : (
          <Text as="p" color="midnight" italic>No reviews found for this station.</Text>
        )}
        <br />
        <Button
          as={Link}
          to={`/station/${stationId}`}
          primary
          fluid
        >
          View Full Station Details
        </Button>
        <br />
      </Container>
    </ExpandableContainer>
  );
}

VerboseStationInfo.propTypes = {
  amenities: PropTypes.arrayOf(AmenityPropType),
  reviews: PropTypes.arrayOf(ReviewPropType),
  stationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

VerboseStationInfo.defaultProps = {
  amenities: [],
  reviews: null,
};
