import { useMemo } from 'react';
import { COUNTRY_CODES } from '../../common/constants';
import useGeoIpInfo from './useGeoIpInfo';
import useProfile from './useProfile';

const { USA } = COUNTRY_CODES;

// ONGOING: If any logic changes are made to this hook, be sure to update the README
export default function useStationPayAvailability(station = {}) {
  const userGeoCountry = useGeoIpInfo().country;
  const userProfile = useProfile();

  return useMemo(() => {
    // A user not located in the US that is not a current Pay user, should not see any pay-related things
    if (userGeoCountry !== USA && !userProfile.isPayUser) {
      return undefined; // see below comment for why we return undefined
    }

    const { address, payStatus } = station;
    const { country: stationCountry } = address || {};

    // this is only false if the station is in the US and is specifically set that isPayAvailable is false
    return stationCountry === USA && payStatus?.isPayAvailable !== false;
  }, [station, userGeoCountry, userProfile]);
}
