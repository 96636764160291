import { useCallback, useEffect, useState } from 'react';
import { ANALYTICS_EVENTS } from '../../common/constants';
import useAnalytics from './useAnalytics';
import useConfig from './useConfig';

export default function useGoogleOneTap(gAppId, hasLoggedIn) {
  const { identityHost } = useConfig();
  const [identityAuthPayload, setIdentityAuthPayload] = useState();
  const analytics = useAnalytics();
  const { google } = typeof window === 'undefined' ? {} : window;

  const initializeCallback = useCallback((googleOneTapResponse) => {
    fetch(`//${identityHost}/social/login/google`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ token: googleOneTapResponse?.credential }),
    }).then(response => response.json())
      .then((identityLoginResult) => {
        setIdentityAuthPayload({
          credential: googleOneTapResponse?.credential,
          ...identityLoginResult,
        });
      })
      .catch((error) => {
        setIdentityAuthPayload({
          code: error.response.code,
          email: error.response.socialEmail,
          status: error.response.statusCode,
          message: error.response.message,
          credential: googleOneTapResponse?.credential,
        });
      });
  }, [identityHost]);

  useEffect(() => {
    if (google?.accounts && gAppId) {
      google.accounts.id.initialize({
        client_id: `${gAppId}.apps.googleusercontent.com`,
        callback: initializeCallback,
      });

      // show prompt UI if no user has been found
      if (!hasLoggedIn) {
        google.accounts.id.prompt((notification) => {
          // Record if OneTap was displayed to the user
          if (notification.isDisplayed()) {
            analytics.tagEvent({ name: ANALYTICS_EVENTS.GOOGLE_ONETAP_IMPRESSION });
          }

          // Record if the user gave consent to authenticate
          if (notification.getDismissedReason() === 'credential_returned') {
            analytics.tagEvent({ name: ANALYTICS_EVENTS.GOOGLE_ONETAP_CLICKED });
          }
        });
      }
    }
  }, [analytics, gAppId, google, hasLoggedIn, initializeCallback]);

  return identityAuthPayload;
}
