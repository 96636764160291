import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Actions, Button, FlexGrid, Header, Image, Modal, Panel, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useProfile from '../../../lib/hooks/useProfile';
import { toReadableMonthYear } from '../../../lib/utils';
import { ANALYTICS_EVENTS, DEFAULT_LOGO_URL } from '../../constants';
import { options } from '../../constants/reviewRatings';
import styles from './ReviewItem.module.css';

const cx = classnames.bind(styles);

export const OPINE_REVIEW = gql`
  mutation OpineReview($isAgreeing: Boolean!, $reviewId: ID!) {
    opineReview(isAgreeing: $isAgreeing, reviewId: $reviewId)
  }
`;

export const FLAG_REVIEW = gql`
  mutation FlagReview($recaptchaResponse: String!, $reviewId: ID!) {
    flagReview(recaptchaResponse: $recaptchaResponse, reviewId: $reviewId)
  }
`;

export default function ReviewItem({
  interactive,
  review,
  showReplies,
}) {
  const isAnonUser = !useProfile().isLoggedIn;
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [recaptchaAttempted, setRecaptchaAttempted] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState();
  const analytics = useAnalytics();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [opineReview, { error: opineError, loading: isOpining }] = useMutation(OPINE_REVIEW, {
    update(cache, { data }) {
      // update cache with new isReportable and hasAgreed values
      cache.modify({
        id: cache.identify(review),
        fields: {
          agreeTotal(oldAgreeTotal) {
            return oldAgreeTotal + (data.opineReview ? 1 : -1);
          },
          hasAgreed() {
            return data.opineReview;
          },
          isReportable() {
            return !data.opineReview;
          },
        },
      });
    },
  });

  const [flagReview, { called: flagCompleted, error: flagError, loading: isFlagging }] = useMutation(FLAG_REVIEW, {
    update(cache, { data }) {
      // update cache with new isReportable value
      cache.modify({
        id: cache.identify(review),
        fields: {
          isReportable() {
            return data.flagReview;
          },
        },
      });

      analytics.tagEvent({ name: ANALYTICS_EVENTS.REVIEW_FLAGGED });
    },
  });

  const redirectAnonUser = useCallback(() => {
    window.location.href = '/login';
  }, []);


  const handleAgreeWithReview = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.REVIEW_AGREED,
      attributes: { isLoggedIn: !isAnonUser },
    });

    if (isAnonUser) {
      redirectAnonUser();
      return;
    }

    opineReview({
      variables: {
        isAgreeing: true,
        reviewId: review.reviewId,
      },
    });
  }, [analytics, isAnonUser, opineReview, review.reviewId, redirectAnonUser]);

  const handleUnagreeWithReview = useCallback(() => {
    // since only logged in users can unagree review, adding context whether user is logged in or not isn't needed
    analytics.tagEvent({ name: ANALYTICS_EVENTS.REVIEW_UNAGREED });

    opineReview({
      variables: {
        isAgreeing: false,
        reviewId: review.reviewId,
      },
    });
  }, [analytics, opineReview, review.reviewId]);

  const handleCancelFlagReview = useCallback(() => {
    setShowFlagModal(false);
    setRecaptchaToken(undefined);
    setRecaptchaAttempted(false);
  }, []);

  const handleFlagReview = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.REVIEW_FLAG_CLICKED,
      attributes: { isLoggedIn: !isAnonUser },
    });

    if (isAnonUser) {
      redirectAnonUser();
      return;
    }
    setShowFlagModal(true);
  }, [analytics, isAnonUser, redirectAnonUser]);

  const handleConfirmFlagReview = useCallback(async () => {
    const responseToken = await executeRecaptcha('flagReview');
    setRecaptchaToken(responseToken);
    setRecaptchaAttempted(true);
    if (responseToken) {
      flagReview({
        variables: {
          recaptchaResponse: responseToken,
          reviewId: review.reviewId,
        },
      });
    }
  }, [executeRecaptcha, flagReview, review.reviewId]);

  const {
    agreeTotal,
    hasAgreed,
    isReportable,
    memberId,
    overallRating,
    profileImageUrl,
    replies,
    review: reviewContent,
    reviewDate,
  } = review;

  const reviewRating = overallRating < 1 ? 1 : overallRating; // safe non 0 ratings
  const formattedReviewDate = toReadableMonthYear(reviewDate);
  const reviewRatingOption = options.find(option => option.value === reviewRating);

  const updateError = opineError || flagError;
  const isUpdating = isOpining || isFlagging;

  return (
    <Panel bordered className={cx('reviewItemPanel')} color="white" rounded>
      {showFlagModal && (
        <Modal
          size="sm"
          content={() => (
            flagCompleted
              ? (
                <Fragment>
                  <Header as="h3">Thanks for your feedback</Header>
                  <Text as="p">
                    The review has been flagged as inappropriate. Someone will follow up soon. Thanks for your help!
                  </Text>
                  <br />
                  <Button primary wide onClick={handleCancelFlagReview}>Done</Button>
                </Fragment>
              )
              : (
                <Fragment>
                  <Header as="h3">Are you sure you want to flag this station review as being inappropriate?</Header>
                  <Text as="p">
                    Inappropriate reviews can include, but are not limited to swearing, unnecessary insults, etc.
                  </Text>
                  <Text as="p">
                    Once you flag a review, you will be unable to agree with it and we will verify that you are not a robot <span role="img" aria-labelledby="recaptcha">🤖</span>
                  </Text>
                  <br /><br />
                  {recaptchaAttempted && !recaptchaToken && (
                    <Text as="p" color="orange">
                      Verification failed. Unable to flag review at this time.
                    </Text>
                  )}
                  {!!updateError && (
                    <Text as="p" color="orange">
                      An error occurred while updating review. Please try again later.
                    </Text>
                  )}
                  <Actions>
                    <Actions.Button
                      primary
                      fluid
                      onClick={handleConfirmFlagReview}
                      loading={isUpdating}
                      disabled={!executeRecaptcha}
                      desktop={4}
                    >
                      Yes, I&rsquo;m sure
                    </Actions.Button>
                    <Actions.Button
                      secondary
                      fluid
                      onClick={handleCancelFlagReview}
                      desktop={4}
                    >
                      Cancel
                    </Actions.Button>
                  </Actions>
                </Fragment>
              )
          )}
          forceIsShowing={showFlagModal}
          onClose={handleCancelFlagReview}
        />
      )}
      <FlexGrid>
        <FlexGrid.Column mobile={8} tablet={10} className={cx('headerContainer')}>
          <div className={cx('inlineContainer')}>
            <Image
              alt={`${memberId}'s avatar image`}
              circular
              className={cx('iconSizing')}
              src={profileImageUrl}
            />
          </div>
          <div className={cx('titleContainer')}>
            <Text as="a" color="midnight" href={`https://www.gasbuddy.com/member/${memberId.toLowerCase()}`}>
              <Text bold color="midnight">{memberId}</Text>
            </Text>
            <br />
            <Text className={cx('date')}>{formattedReviewDate}</Text>
          </div>
        </FlexGrid.Column>
        <FlexGrid.Column mobile={4} tablet={2} className={cx('ratingContainer')}>
          <Image
            alt={`${reviewRatingOption.label} Review Icon`}
            className={cx('iconSizing')}
            src={reviewRatingOption.icon}
          />
        </FlexGrid.Column>
        <FlexGrid.Column>
          <Text as="p" className={cx('content')}>{reviewContent}</Text>
        </FlexGrid.Column>
        {interactive && (
          <Fragment>
            <FlexGrid.Column mobile={8}>
              {(isAnonUser || isReportable) && (
                <Button link className={cx('flagButton')} onClick={handleFlagReview}>
                  Flag as inappropriate
                </Button>
              )}
              {!isAnonUser && !hasAgreed && !isReportable && (
                <Text className={cx('flagged')}>Flagged as inappropriate!</Text>
              )}
            </FlexGrid.Column>
            <FlexGrid.Column mobile={4} className={cx('agreeButtonContainer')}>
              {(isAnonUser || hasAgreed || isReportable) && (
                <Button
                  className={cx('agreeButton', { agreed: hasAgreed })}
                  loading={isUpdating}
                  onClick={hasAgreed ? handleUnagreeWithReview : handleAgreeWithReview}
                  size="sm"
                >
                  <Image
                    alt="Agree Icon"
                    className={cx('agreeIcon')}
                    src={hasAgreed ? '//static.gasbuddy.com/web/icons/thumbsup-active.svg' : '//static.gasbuddy.com/web/icons/thumbsup.svg'}
                  />
                  &nbsp;&nbsp;
                  {Number(agreeTotal) > 0 ? `${agreeTotal} Agree` : 'Agree ?'}
                </Button>
              )}
            </FlexGrid.Column>
            {!!updateError && (
              <FlexGrid.Column>
                <Text as="p" color="orange">{updateError}</Text>
              </FlexGrid.Column>
            )}
          </Fragment>
        )}
        {showReplies && replies.length > 0 && replies.map((reply) => {
          const formattedReplyDate = toReadableMonthYear(reply.createDate);
          return (
            <FlexGrid.Column mobile={10} mobileOffset={2} key={reply.id}>
              <div className={cx('replyContainer')}>
                <div className={cx('headerContainer')}>
                  <Image
                    src={(reply.brand?.imageUrl) || DEFAULT_LOGO_URL}
                    className={cx('replyIcon')}
                    alt={`${(reply.brand?.name) || ''} corporate logo`}
                    circular
                    bordered
                  />
                  <div className={cx('titleContainer')}>
                    <Text bold color="midnight" className={cx('replyIcon')}>
                      {`Reply from ${reply.authorName}`}
                    </Text>
                    <br />
                    <Text className={cx('date')}>{formattedReplyDate}</Text>
                  </div>
                </div>
                <Text as="p" className={cx('content')}>{reply.text}</Text>
              </div>
            </FlexGrid.Column>
          );
        })}
      </FlexGrid>
    </Panel>
  );
}

ReviewItem.propTypes = {
  interactive: PropTypes.bool,
  review: PropTypes.shape({
    agreeTotal: PropTypes.number,
    hasAgreed: PropTypes.bool,
    isReportable: PropTypes.bool,
    memberId: PropTypes.string.isRequired,
    overallRating: PropTypes.number,
    profileImageUrl: PropTypes.string,
    replies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        createDate: PropTypes.string,
        brandId: PropTypes.number,
        authorName: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    review: PropTypes.string,
    reviewId: PropTypes.string,
    reviewDate: PropTypes.string,
  }).isRequired,
  showReplies: PropTypes.bool,
};

ReviewItem.defaultProps = {
  interactive: false,
  showReplies: false,
};
