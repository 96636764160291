import { Fragment, useState, useCallback, useEffect } from 'react';
import classnames from 'classnames/bind';
import { Header, Text, Button, Input, Actions, Link } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import styles from './EditMemberNameStatus.module.css';
import { isValidMemberName, noop } from '../../../lib/utils';

const cx = classnames.bind(styles);

export const USERNAME_STATE = {
  EDITABLE: 1,
  CUSTOMIZED: 2,
  UPDATED: 3,
};

export default function EditMemberNameStatus({
  error,
  isLoading,
  memberName,
  onClose,
  onSubmit,
  variant,
}) {
  const [userName, setUserName] = useState(memberName || '');
  const [userNameError, setUserNameError] = useState();

  useEffect(() => {
    setUserNameError(error);
  }, [error]);

  const onUserNameChange = useCallback((e) => {
    e.preventDefault();
    setUserNameError(undefined);
    setUserName(e.target.value);
  }, []);

  const handleOnSubmit = useCallback(() => {
    if (userName.length < 5 || userName.length > 15) {
      setUserNameError('Username must be between 5 and 15 characters long');
      return;
    }

    if (!isValidMemberName(userName)) {
      setUserNameError('Username cannot include special characters');
      return;
    }
    onSubmit(userName);
  }, [userName, onSubmit]);

  const isDisabled = isLoading || !userName;

  switch (variant) {
    case USERNAME_STATE.EDITABLE: {
      return (
        <Fragment>
          <Header as="h3">Edit your username</Header>
          <Text as="p" className={cx('textSpacer')}>
            Choose a username that other GasBuddy members will see.
            You can only set your username once.
            No pressure.
            You got this!
          </Text>
          <Input
            className={cx('memberNameInput')}
            data-testid="newMemberNameInput"
            value={userName}
            onChange={onUserNameChange}
            error={userNameError}
            placeholder="Enter New Username"
          />
          <br />
          <Actions>
            <Actions.Button
              primary
              fluid
              onClick={handleOnSubmit}
              loading={isLoading}
              disabled={isDisabled}
              desktop={6}
            >
              Set Username
            </Actions.Button>
            <Actions.Action desktop={4}>
              <Link onClick={onClose} uppercase>
                Cancel
              </Link>
            </Actions.Action>
          </Actions>
          <br />
          <Text as="p" size="sm">
            *Once submitted, this page will refresh to reflect your new username.
          </Text>
        </Fragment>
      );
    }

    case USERNAME_STATE.UPDATED: {
      return (
        <Fragment>
          <Header as="h3">Username updated!</Header>
          <Text as="p">
            {userName} has a nice ring to it!
          </Text>
          <Text as="p">
            This page will automatically refresh with your new username.
          </Text>
        </Fragment>
      );
    }

    case USERNAME_STATE.CUSTOMIZED: {
      return (
        <Fragment>
          <Header as="h3">You’ve already set your username.</Header>
          <Text as="p">
            Unfortunately, you can only personalize your username once.
          </Text>
          <br />
          <Button primary wide onClick={onClose}>Okay</Button>
        </Fragment>
      );
    }

    default: {
      return false;
    }
  }
}

EditMemberNameStatus.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  memberName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(USERNAME_STATE)),
};

EditMemberNameStatus.defaultProps = {
  error: undefined,
  isLoading: false,
  memberName: undefined,
  onClose: noop,
  onSubmit: noop,
  variant: undefined,
};
