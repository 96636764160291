import { useMutation } from '@apollo/client';
import { Button, Text, Tray } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import useProfile from '../../../lib/hooks/useProfile';
import { noop } from '../../../lib/utils';
import CountdownClock from '../CountdownClock';
import styles from './DeleteAccountTray.module.css';

const cx = classnames.bind(styles);

export const RESTORE_ACCOUNT_REQUEST = gql`
  mutation RestoreAccountRequest {
    restoreAccountRequest {
      success
    }
  }
`;

export default function DeleteAccountTray({ endDate, isProcessing, onRestoreAccount }) {
  const [timerEnded, setTimerEnded] = useState(endDate && new Date().getTime() >= Date.parse(endDate));
  const [hasRequestedRestoration, setHasRequestedRestoration] = useState(false);
  const [restoreRequestTimeout, setRestoreRequestTimeout] = useState();
  const profile = useProfile();

  const [performRestoreRequest, { loading: restoreLoading }] = useMutation(RESTORE_ACCOUNT_REQUEST, {
    update(cache, { data }) {
      if (data?.restoreAccountRequest?.success) {
        setRestoreRequestTimeout(setTimeout(() => {
          cache.modify({
            id: cache.identify(profile),
            fields: {
              deletionStatus() {
                return {
                  endDate: null,
                  status: null,
                };
              },
            },
          });
        }, 5000));
      }
    },
    onCompleted: (data) => {
      if (data?.restoreAccountRequest?.success) {
        setHasRequestedRestoration(true);
      }
    },
  });

  useEffect(() => () => {
    if (restoreRequestTimeout) {
      clearTimeout(restoreRequestTimeout);
    }
  }, [restoreRequestTimeout]);

  const handleRestoreAccount = useCallback(() => {
    performRestoreRequest();
    onRestoreAccount();
  }, [onRestoreAccount, performRestoreRequest]);

  const handleTimerEnd = useCallback(() => {
    setTimerEnded(true);
  }, []);

  let trayText = (
    <Text as="p" className={cx('restore-text')}>
      Your account will be processed for deletion in less than 48 hours.
      To change your mind, click the Restore My Account button and we will stop the deletion process.
    </Text>
  );

  if (isProcessing || timerEnded) {
    trayText = (
      <Text as="p" className={cx('solitary-text')}>
        Your account deletion request is currently being processed. This is not reversible.
        <br />
        If you have any questions or concerns, please contact our support team.
      </Text>
    );
  } else if (hasRequestedRestoration) {
    trayText = (
      <Text as="p" className={cx('solitary-text')}>
        Your request to restore your account has been processed. Your account will not be deleted.
        <br />
        This page will refresh in a few seconds to reflect the change to your account.
      </Text>
    );
  }

  return (
    <Tray className={cx('delete-account-tray')} isOpen mobile={false}>
      <div className={cx('content-wrapper')}>
        {trayText}
        {!isProcessing && !timerEnded && !hasRequestedRestoration && (
          <Fragment>
            <CountdownClock
              endDate={endDate}
              labelled
              onEnd={handleTimerEnd}
            />
            <Button
              primary
              onClick={handleRestoreAccount}
              loading={restoreLoading}
            >
              Restore My Account
            </Button>
          </Fragment>
        )}
      </div>
    </Tray>
  );
}

DeleteAccountTray.propTypes = {
  endDate: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool,
  onRestoreAccount: PropTypes.func,
};

DeleteAccountTray.defaultProps = {
  isProcessing: false,
  onRestoreAccount: noop,
};
