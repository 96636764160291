import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Image, Input, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { noop } from '../../../lib/utils';
import { KEYNAMES } from '../../constants';
import styles from './GasPriceEditor.module.css';

const cx = classnames.bind(styles);

/**
 * A component that can be used to edit a single fuel price on both mobile and desktop devices
 *
 * @class GasPriceEditor
 * @extends {React.Component}
 */
export default function GasPriceEditor({
  error,
  inputId,
  isCash,
  isConfirmed: initialIsConfirmed,
  isInDollars,
  onConfirm,
  showBadge,
  value: initialValue,
  ...rest
}) {
  const getInputValue = v => (v ? v.toString() : '');
  const initialTemplateValue = isInDollars ? '##.##' : '###.#';

  const [value, setValue] = useState(getInputValue(initialValue));
  const [templateValue, setTemplateValue] = useState(initialTemplateValue);
  const [isConfirmed, setIsConfirmed] = useState(initialIsConfirmed);
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = useCallback(() => {
    if (value === '') {
      setValue(getInputValue(initialValue));
      onConfirm(undefined);
    } else {
      setIsConfirmed(true);
      onConfirm(value);
    }

    setIsEditing(false);
  }, [initialValue, onConfirm, value]);

  const handleFocus = useCallback(() => {
    if (value === getInputValue(initialValue) && !isConfirmed) {
      setValue('');
    }

    setIsConfirmed(false);
    setIsEditing(true);
  }, [initialValue, isConfirmed, value]);

  const handleNewTemplateValue = useCallback((updatedTemplateValue) => {
    // ensure we've unconfirmed the price
    if (isConfirmed) {
      onConfirm(undefined);
      setIsConfirmed(false);
    }

    let formattedTemplateValue = updatedTemplateValue;
    if (formattedTemplateValue.indexOf('.') < 0) {
      const decimalIndex = isInDollars ? 2 : 3;
      formattedTemplateValue = [formattedTemplateValue.slice(0, decimalIndex), formattedTemplateValue.slice(decimalIndex)].join('.');
    }
    // update our template value state
    setTemplateValue(formattedTemplateValue);

    // update our value state as appropriate to the new template value
    if (formattedTemplateValue.replace('.', '').match(/^#+$/g)) {
      setValue(''); // set to blank if our template is just #s
    } else {
      // we have at least one digit, so replace the #s adjacent to the . with 0s; the rest can be removed
      // 2022-03-10 Note: safari does not support lookbehind with regex (because of course it doesn't)
      setValue(formattedTemplateValue
        .replace(/##\.#/g, '#0.0') // replaces ##.#9 with #0.09
        .replace(/#(?=\.)/g, '0') // replaces ##.19 with #0.19 or ###.9 with ##0.9
        .replace(/##(?=\d\.)/g, '#0') // replaces ##0.9 with #00.9 (so we always have 4 chars in value)
        .replace(/#/g, ''));
    }
  }, [isConfirmed, isInDollars, onConfirm]);

  const handleDigitKeyDown = useCallback(({ key: digitKey, target }) => {
    // Do not continue if our template is full (ie. we don't have a leading # to chop off)
    if (templateValue[0] === '#') {
      let newNoDecimalTemplateValue;

      if (!target.value || target.selectionStart >= target.value.length) {
        // New digit goes at end of our template if our input value is empty or if cursor is at the end of input value
        newNoDecimalTemplateValue = `${templateValue.replace('.', '').slice(1)}${digitKey}`;
      } else {
        // New digit needs to get spliced into the spot specified by selectionStart
        // Use the current template value for this as it has a . in it similar to how our target.value has a . in it
        const insertedDigitTemplateValue = `${templateValue.slice(0, target.selectionStart + 1)}${digitKey}${templateValue.slice(target.selectionStart + 1)}`;

        // At this point, we'll have something like ##.421 or #10.21 or #1#.#4
        // Since we need to end with a decimal-less template value, remove the decimal here and chop off a leading #
        newNoDecimalTemplateValue = insertedDigitTemplateValue.replace('.', '').slice(1);

        // Replace any #s between digits with a 0
        // ie. #4#5 => #405 or 1##5 => 1005; #205 won't match as it is fine to proceed
        const placeSkips = newNoDecimalTemplateValue.match(/\d+#+\d+$/g);
        if (placeSkips?.length > 0) {
          const skipIndex = newNoDecimalTemplateValue.indexOf(placeSkips[0]);
          newNoDecimalTemplateValue = `${newNoDecimalTemplateValue.slice(0, skipIndex)}${newNoDecimalTemplateValue.slice(skipIndex).replace(/#/g, '0')}`;
        }
      }

      // Send our new template value to get its decimal placed properly and #s turned to 0s or blanks
      handleNewTemplateValue(newNoDecimalTemplateValue);
    }
  }, [handleNewTemplateValue, templateValue]);

  const handleBackspaceKeyDown = useCallback(({ target }) => {
    const noDecimalTemplateValue = templateValue.replace('.', '');
    if (!noDecimalTemplateValue.match(/^#+$/g)) { // no need to backspace an empty value
      let newNoDecimalTemplateValue;
      if (target.selectionStart >= target.value.length) {
        // Cursor is at end of input value, so just remove the last digit
        newNoDecimalTemplateValue = `${noDecimalTemplateValue.slice(0, noDecimalTemplateValue.length - 1)}`;
      } else if (target.selectionEnd - target.selectionStart >= target.value.length) {
        // The entire input is highlighted and requested to be deleted, so clear it
        newNoDecimalTemplateValue = '###';
      } else {
        // Cursor is not at end of input value, so we need to remove the correct digit
        // We want to remove the character behind the current cursor position, or the one behind that one if
        // it happens to be the . in the template
        // If our input value is not the same length as our template value, we need to account for that
        // when we check the correct character in the input
        // Ex: 4.56 input value will have #4.56 template value. (lengths differ by 1)
        //    If cursor position comes back as 2, input[1] === '.', but template[1] === '4' (which is already the character we want to remove)
        // Ex: 10.34 input value will have 10.34 template value. (lengths are same)
        //    If cursor position comes back as 3, input[2] === '.' and template[2] === '.' (so we should go back to template[1], which is '0')
        const decimalCheckModifier = templateValue.length === target.value.length ? 1 : 0;
        const indexModifier = templateValue[target.selectionStart - decimalCheckModifier] === '.' ? decimalCheckModifier + 1 : decimalCheckModifier;

        // Now that we know the character index to remove, remove it, then remove the . for our new template value
        const removedDigitTemplateValue = `${templateValue.slice(0, target.selectionStart - indexModifier)}${templateValue.slice(target.selectionStart - indexModifier + 1)}`;
        newNoDecimalTemplateValue = removedDigitTemplateValue.replace('.', '');
      }

      // Add a # to the beginning of our new template value for the digit that was removed
      handleNewTemplateValue(`#${newNoDecimalTemplateValue}`);
    }
  }, [handleNewTemplateValue, templateValue]);

  const handlePaste = useCallback((e) => {
    // get data from clipboard, removing any currency symbols
    const dataToPaste = e.clipboardData.getData('text').replace('$', '').replace('¢', '');

    // Only handle clipboard data that can be a price
    if (dataToPaste.match(/^\d+\.?\d+$/g)) {
      // Just replace everything in the input with the clipboard data
      handleNewTemplateValue(dataToPaste.replace('.', '').padStart(4, '#').slice(0, 4));
    }
  }, [handleNewTemplateValue]);

  const handleKeyDown = useCallback((e) => {
    // Note: Android Chromium browsers set the keyCode to 229 or 0 for all inputs
    // Therefore, we let the beforeInput event handle inputs for those browsers (which happens after the key events)
    if (e.keyCode !== 229 && e.keyCode !== 0) {
      // do not prevent tab, arrow, or some ctrl events
      if (e.key !== KEYNAMES.TAB && !e.key.includes('Arrow') && !((e.ctrlKey || e.metaKey) && ['a', 'c', 'v'].includes(e.key))) {
        e.preventDefault();
      }

      // We only want to handle keys pressed that are digits or a Backspace
      if (e.key.match(/\d/)) {
        handleDigitKeyDown(e);
      } else if (e.key === KEYNAMES.BACKSPACE) {
        handleBackspaceKeyDown(e);
      }
    }
  }, [handleBackspaceKeyDown, handleDigitKeyDown]);

  const handleBeforeInput = useCallback((e) => {
    // handle key presses from Android Chromium browsers
    if (e.data.match(/\d/)) {
      handleDigitKeyDown({
        key: e.data,
        target: e.target,
      });
    }
    // Note: this event cannot capture backspaces, so we rely on the trash icon for users to clear a price
  }, [handleDigitKeyDown]);

  const handleConfirm = useCallback((e) => {
    e.preventDefault();
    setValue(value);
    setTemplateValue(value.padStart(initialTemplateValue.length, '#'));
    setIsConfirmed(true);
    onConfirm(value);
  }, [initialTemplateValue.length, onConfirm, value]);

  const handleClearPrice = useCallback(() => {
    setTemplateValue(isInDollars ? '##.##' : '###.#');
    setIsConfirmed(false);
    setIsEditing(false);
    setValue(getInputValue(initialValue));
    onConfirm(undefined);
  }, [initialValue, isInDollars, onConfirm]);

  useEffect(() => {
    setIsConfirmed(initialIsConfirmed);
  }, [initialIsConfirmed]);

  useEffect(() => {
    setValue(getInputValue(initialValue));
  }, [initialValue]);

  return (
    <Fragment>
      <div className={cx('gasPriceEditor')}>
        <Input
          {...rest}
          className={cx('priceInput', { confirmed: isConfirmed, hasBadge: showBadge, hasError: !!error })}
          id={inputId}
          onBeforeInput={handleBeforeInput}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onPaste={handlePaste}
          value={value}
        />
        <span className={cx('badge', { creditBadge: !isCash, hidden: !showBadge })}>
          {isCash ? 'CASH' : 'CREDIT'}
        </span>
        {isConfirmed && !error && (
          <Image
            alt="Price Confirmed Icon"
            className={cx('priceConfirmedIcon')}
            data-testid="priceConfirmedIcon"
            src="https://static.gasbuddy.com/web/consumer/button-quick-report-activated-green.svg"
          />
        )}
        {!!value && isConfirmed && (
          <FontAwesomeIcon
            className={cx('clearPriceIcon')}
            icon={faTrashAlt}
            onClick={handleClearPrice}
            size="2x"
            title="Click to clear this price"
            data-testid="clearPriceIcon"
          />
        )}
        {!isConfirmed && !isEditing && !!value && (
          <Button link onClick={handleConfirm}>{`Confirm ${value}`}</Button>
        )}
      </div>
      {!!error && (
        <Text
          as="p"
          className={cx('errorMessage')}
          color="orange"
          data-testid="errorMessage"
        >
          {error}
        </Text>
      )}
    </Fragment>
  );
}

GasPriceEditor.propTypes = {
  /** A string used to signify an error for the editor */
  error: PropTypes.string,
  /** A unique string used to identify the input in this component */
  inputId: PropTypes.string.isRequired,
  /** A boolean to determine if the price is cash-only */
  isCash: PropTypes.bool,
  /** A boolean to determine if the price is already confirmed */
  isConfirmed: PropTypes.bool,
  /** A boolean to determine if the prices should be in dollars or cents */
  isInDollars: PropTypes.bool,
  /** A callback executed when a user confirms the fuel price */
  onConfirm: PropTypes.func,
  /** A boolean to determine whether the CASH and CREDIT badge should show */
  showBadge: PropTypes.bool,
  /** The desired input value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

GasPriceEditor.defaultProps = {
  error: undefined,
  isCash: false,
  isConfirmed: false,
  isInDollars: false,
  onConfirm: noop,
  showBadge: false,
  value: undefined,
};
