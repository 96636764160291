import { Text } from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import ReportedBy from '../ReportedBy';
import styles from './StationDisplayPrice.module.css';
import { OUTDATED_PRICE_DISPLAY } from '../../constants';

const cx = classnames.bind(styles);

export default function StationDisplayPrice({ asCard, bordered, formattedPrice, isCash, postedTime, reportedBy }) {
  const { mobile: isMobileView } = useWindowSize();

  return (
    <div className={cx('priceContainer', { asCard: isMobileView || asCard })}>
      <div className={cx('borderContainer', { bordered })}>
        <Text className={cx('price')} size="xl">
          {formattedPrice}
        </Text>

        {reportedBy && (
          <Fragment>
            <ReportedBy
              username={reportedBy}
              time={postedTime}
            />
            {isCash && (
              <Text className={cx('cashTag')}>CASH</Text>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

StationDisplayPrice.propTypes = {
  asCard: PropTypes.bool,
  bordered: PropTypes.bool,
  formattedPrice: PropTypes.string,
  isCash: PropTypes.bool,
  postedTime: PropTypes.string,
  reportedBy: PropTypes.string,
};

StationDisplayPrice.defaultProps = {
  asCard: false,
  bordered: false,
  formattedPrice: OUTDATED_PRICE_DISPLAY,
  isCash: false,
  postedTime: undefined,
  reportedBy: undefined,
};
