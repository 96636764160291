import { useMutation, useQuery } from '@apollo/client';
import { faHeart as faHeartFilled } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHeart as faHeartEmpty } from '@fortawesome/pro-light-svg-icons/faHeart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Modal, Text, Visibility } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import useConfig from '../../../lib/hooks/useConfig';
import useProfile from '../../../lib/hooks/useProfile';
import { GET_FAVORITE_LIST } from '../ManageFavoriteStations/ManageFavoriteStations';
import { GET_STATION } from '../Station/Station.gql';
import AddStationToFavorites from './AddStationToFavorites';
import DeleteStationFromFavorites from './DeleteStationFromFavorites';
import styles from './FavoriteStationIcon.module.css';

const cx = classnames.bind(styles);

export const SAVE_STATION = gql`
  mutation AddStationToFavoriteList($stationId: String!, $listId: String) {
    addStationToFavorites(stationId: $stationId, listId: $listId) {
      id
      stationIds
    }
  }
`;

export const REMOVE_STATION = gql`
  mutation RemoveStationFromFavoriteList($stationId: String!, $listId: String) {
    removeStationFromFavorites(stationId: $stationId, listId: $listId) {
      id
      stationIds
    }
  }
`;

export const GET_FAVORITE_LISTS = gql`
  query GetFavoriteStationLists {
    favoriteStationLists {
      displayName
      id
      isDefault
      name
      stationIds
    }
  }
`;

export default function FavoriteStationIcon({
  size,
  stationId,
  verbose,
}) {
  const { isLoggedIn } = useProfile();
  const { identityHost } = useConfig();
  const [isShowingModal, setIsShowingModal] = useState(false);
  const closeModal = useCallback(() => { setIsShowingModal(false); }, []);
  const { data, error: fetchError, loading: isFetching } = useQuery(GET_FAVORITE_LISTS, {
    skip: !isLoggedIn,
  });
  const favoriteLists = data?.favoriteStationLists;
  const activeList = favoriteLists?.find(list => list.stationIds.includes(parseInt(stationId, 10)));
  const [saveStation, { error: saveError, loading: isSaving }] = useMutation(SAVE_STATION, {
    onCompleted: closeModal,
    update(cache, { data: saveStationData }) {
      const listId = saveStationData.addStationToFavorites.id;
      const listInfo = cache.readQuery({ query: GET_FAVORITE_LIST, variables: { id: listId } });
      const newStationInfo = cache.readQuery({ query: GET_STATION, variables: { id: stationId } });

      if (listInfo && newStationInfo) {
        const newStations = saveStationData.addStationToFavorites.stationIds.map((id) => {
          if (id === stationId) {
            const { __typename, ...rest } = newStationInfo.station;
            return rest;
          }
          const { __typename, ...rest } = listInfo.favoriteStationList.stations.find(station => station.id === id.toString()) || {};
          return rest;
        });
        cache.writeQuery({
          query: GET_FAVORITE_LIST,
          data: {
            favoriteStationList: {
              ...listInfo.favoriteStationList,
              stations: newStations,
            },
          },
          variables: {
            id: listId,
          },
        });
      }
    },
  });

  const [removeStation, { error: deleteError, loading: isDeleting }] = useMutation(REMOVE_STATION, {
    onCompleted: closeModal,
    update(cache, { data: removeStationData }) {
      const listInfo = cache.readQuery({ query: GET_FAVORITE_LIST, variables: { id: activeList?.id } });
      if (listInfo) {
        const newStations = removeStationData.removeStationFromFavorites.stationIds.map((id) => {
          const { __typename, ...rest } = listInfo.favoriteStationList.stations.find(station => station.id === id.toString()) || {};
          return rest;
        });
        cache.writeQuery({
          query: GET_FAVORITE_LIST,
          data: {
            favoriteStationList: {
              ...listInfo.favoriteStationList,
              stations: newStations,
            },
          },
          variables: {
            id: activeList.id,
          },
        });
      }
    },
  });

  let error;
  if (fetchError) {
    error = 'An error occurred while fetching your favorites.';
  } else if (saveError) {
    error = 'An error occurred while adding this station to favorites.';
  } else if (deleteError) {
    error = 'An error occurred while removing this station from favorites.';
  }
  const isFavorite = !!activeList;

  const addStationToList = useCallback((listId) => {
    saveStation({
      variables: {
        listId: listId.toString(),
        stationId: stationId.toString(),
      },
    });
  }, [saveStation, stationId]);

  const handleIconClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn) {
      window.location.href = `//${identityHost}/login?return_url=${window.location.href}`;
    } else {
      setIsShowingModal(true);
    }
  }, [isLoggedIn, identityHost]);

  const handleRemoveFromFavorites = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    removeStation({
      variables: {
        listId: activeList?.id.toString(),
        stationId: stationId.toString(),
      },
    });
  }, [activeList, removeStation, stationId]);

  const handleCloseModal = useCallback((e) => {
    // Modal close doesn't send event object
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsShowingModal(false);
  }, []);

  if (isFetching) {
    return <Loader size={size === '2x' ? 'sm' : 'md'} />;
  }

  let modalContent = isFavorite
    ? (
      <DeleteStationFromFavorites
        favoriteListName={activeList.name}
        onDeleteFromFavorites={handleRemoveFromFavorites}
        onCancel={handleCloseModal}
        error={error && `${error} Please try again later.`}
      />
    )
    : (
      <AddStationToFavorites
        favoriteLists={favoriteLists}
        onAddStationToFavorites={addStationToList}
        onCancel={handleCloseModal}
        error={error && `${error} Please try again later.`}
      />
    );

  if (isSaving || isDeleting) {
    modalContent = <Loader size={size === '2x' ? 'sm' : 'md'} />;
  }

  if (fetchError || !favoriteLists?.length) {
    return null;
  }

  return (
    <div className={cx('favoriteIconContainer', { verbose })}>
      {isLoggedIn && (
        <Modal
          content={modalContent}
          forceIsShowing={isShowingModal}
          onClose={handleCloseModal}
          size="sm"
        />
      )}
      {/* when modal disappears after favoriting/unfavoriting station, there is a short delay the loader would appear */}
      {isSaving || isDeleting
        ? <Loader size={size === '2x' ? 'sm' : 'md'} />
        : (
          <FontAwesomeIcon
            icon={isFavorite ? faHeartFilled : faHeartEmpty}
            size={size}
            className={cx('favoriteIcon', { isFavorite })}
            onClick={handleIconClick}
          />
        )
      }
      {verbose && (
        <Visibility mobile={false}>
          {!isSaving && !isDeleting && <br />}
          <Text>
            {isFavorite
              ? (
                <Fragment>
                  Added to&nbsp;
                  <Link to="/account/favorite-lists" className={cx('favoritesLink')}>Favorite Stations</Link>
                </Fragment>
              )
              : 'Add to Favorite Stations'
            }
          </Text>
        </Visibility>
      )}
    </div>
  );
}

FavoriteStationIcon.propTypes = {
  size: PropTypes.oneOf(['2x', '3x']),
  stationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  verbose: PropTypes.bool,
};

FavoriteStationIcon.defaultProps = {
  size: '3x',
  verbose: false,
};
