import { Panel } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import AdContainer from '../Ad/AdContainer';
import { AdParams, AdSizesPropType } from '../../prop-types/ad';
import styles from './AdBanner.module.css';

const cx = classnames.bind(styles);

export function AdBanner({ paths, params }) {
  return (
    <div>
      <Panel padded className={cx('adBanner')}>
        {
          paths.map(({ id, path, sizes, sizeMapping }) => (
            <AdContainer
              key={id}
              id={id}
              path={path}
              sizes={sizes}
              sizeMapping={sizeMapping}
              params={params}
              center
            />
          ))
        }
      </Panel>
    </div>
  );
}

AdBanner.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    path: PropTypes.string,
    only: PropTypes.string,
    sizes: AdSizesPropType,
  })).isRequired,
  params: AdParams,
};

AdBanner.defaultProps = {
  params: undefined,
};
