import { ColumnWidthPropType, FlexGrid, Header, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LinkLocationPropType from '../../prop-types/link-location';
import styles from './DataGrid.module.css';

const cx = classnames.bind(styles);

function renderHeader(header) {
  if (typeof header === 'string') {
    return (
      <Header as="h2" className={cx('header')}>
        {header}
      </Header>
    );
  }

  return (typeof header === 'function') ? header() : header;
}

function renderLink(item, onClick) {
  const { url, isActive, displayText } = item;
  const className = cx('link', { active: isActive });

  return typeof url === 'string' && url.startsWith('http')
    ? <a href={url} className={className} onClick={onClick}>{displayText}</a>
    : <Link to={url} className={className} onClick={onClick}>{displayText}</Link>;
}

export default function DataGrid({
  className,
  columns,
  desktop,
  header,
  items,
  mobile,
  onItemClick,
  tablet,
  ...props
}) {
  return (
    <div className={cx('container', className)} {...props}>
      {renderHeader(header)}
      <Panel className={cx('panel')} color="white" bordered rounded>
        <FlexGrid columns={columns}>
          {
            items.map(item => (
              <FlexGrid.Column
                key={item.key}
                className={cx('gridEntry', item.className)}
                mobile={mobile}
                tablet={tablet}
                desktop={desktop}
              >
                {renderLink(item, () => onItemClick(item))}
              </FlexGrid.Column>
            ))
          }
        </FlexGrid>
      </Panel>
    </div>
  );
}

function ColumnsPropType(props, propName) {
  // eslint-disable-next-line react/destructuring-assignment
  if (!props[propName]) {
    return null;
  }

  // eslint-disable-next-line react/prop-types
  const { mobile, tablet, desktop } = props;

  if (mobile || tablet || desktop) {
    return new Error('Prop "columns" and "[mobile|tablet|desktop]" are mutually exclusive');
  }

  // eslint-disable-next-line react/destructuring-assignment
  return PropTypes.checkPropTypes([PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])], props[propName], 'prop', 'DataGrid');
}

DataGrid.propTypes = {
  className: PropTypes.string,
  columns: ColumnsPropType,
  desktop: ColumnWidthPropType,
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  items: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    displayText: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    url: PropTypes.oneOfType([
      PropTypes.string,
      LinkLocationPropType,
    ]).isRequired,
  })),
  mobile: ColumnWidthPropType,
  onItemClick: PropTypes.func,
  tablet: ColumnWidthPropType,
};

DataGrid.defaultProps = {
  className: undefined,
  columns: null,
  desktop: null,
  header: null,
  items: [],
  mobile: null,
  onItemClick: () => {},
  tablet: null,
};
