import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Actions, Link, Modal, Text } from '@gasbuddy/react-components';
import EditMembernameStatus, { USERNAME_STATE } from '../EditMemberNameStatus';
import { noop } from '../../../lib/utils';

export const UPDATE_MEMBER_NAME = gql`
  mutation UpdateMemberName($memberName: String!) {
    updateMemberName(memberName: $memberName) {
      canSetMemberName
      memberName
    }
  }
`;

export default function EditMemberNameModal({
  memberName: generatedMemberName,
  messageType,
  onCloseModal,
  onMemberNameChangeRequested,
  onMemberNameUpdated,
  showModal,
  statusVariant,
}) {
  const [hasRequestedMemberNameChange, setHasRequestedMemberNameChange] = useState(false);
  const [hasChangedMemberName, setHasChangedMemberName] = useState(false);
  const [updateMemberName, { loading: isLoading, error }] = useMutation(UPDATE_MEMBER_NAME);
  const actionText = messageType === 'both' ? 'on GasBuddy' : `this ${messageType}`;

  const onEditMemberName = useCallback(() => {
    setHasRequestedMemberNameChange(true);
    onMemberNameChangeRequested();
  }, [onMemberNameChangeRequested]);

  const handleSubmit = useCallback((newMemberName) => {
    updateMemberName({
      variables: {
        memberName: newMemberName,
      },
      onCompleted: () => {
        onMemberNameUpdated();
        setHasChangedMemberName(true);
      },
    });
  }, [updateMemberName, onMemberNameUpdated]);

  return (
    <Modal
      forceIsShowing={showModal}
      size="sm"
      bgClickable={false}
      closeIcon={false}
      onClose={onCloseModal}
      content={({ close }) => {
        if (!hasRequestedMemberNameChange && statusVariant !== USERNAME_STATE.CUSTOMIZED) {
          return (
            <Fragment>
              <Text as="p">You&apos;ll be posting {actionText} as:</Text>
              <Text as="p" bold>{generatedMemberName}</Text>
              <Text as="p">Would you like to change it to make it your own?</Text>
              <br />
              <Actions>
                <Actions.Button
                  primary
                  wide
                  onClick={close}
                  desktop={6}
                >
                  Not Now
                </Actions.Button>
                <Actions.Action desktop={6}>
                  <Link onClick={onEditMemberName} uppercase>
                    Edit Username
                  </Link>
                </Actions.Action>
              </Actions>
            </Fragment>
          );
        }

        return (
          <EditMembernameStatus
            variant={hasChangedMemberName ? USERNAME_STATE.UPDATED : statusVariant}
            onClose={close}
            onSubmit={handleSubmit}
            error={error && 'An error occurred while updating your username. Please try a different username or try again later.'}
            isLoading={isLoading}
            memberName={generatedMemberName}
          />
        );
      }}
    />
  );
}

EditMemberNameModal.propTypes = {
  memberName: PropTypes.string.isRequired,
  messageType: PropTypes.oneOf(['review', 'price', 'both']).isRequired,
  onCloseModal: PropTypes.func,
  onMemberNameChangeRequested: PropTypes.func,
  onMemberNameUpdated: PropTypes.func,
  showModal: PropTypes.bool,
  statusVariant: PropTypes.number,
};

EditMemberNameModal.defaultProps = {
  onCloseModal: noop,
  onMemberNameChangeRequested: noop,
  onMemberNameUpdated: noop,
  showModal: false,
  statusVariant: USERNAME_STATE.EDITABLE,
};
