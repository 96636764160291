import PropTypes from 'prop-types';

export default PropTypes.shape({
  openingHours: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  nextIntervals: PropTypes.arrayOf(PropTypes.shape({
    open: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
  })).isRequired,
});
