export function generateBreadcrumbSchema(breadcrumbs) {
  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }

  const breadcrumbListSchema = breadcrumbs
    .filter(crumb => crumb.linkTo && crumb.text)
    .map(({ linkTo, text }, idx) => ({
      '@type': 'ListItem',
      position: idx + 1,
      item: {
        '@id': `https://www.gasbuddy.com${linkTo}`,
        name: text,
      },
    }));

  return {
    breadcrumb: {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbListSchema,
    },
  };
}
