import equal from 'fast-deep-equal';
import PropTypes from 'prop-types';
import { memo, useCallback, useContext, useEffect, useRef } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useInView from '../../../lib/hooks/useInView';
import usePageContext from '../../../lib/hooks/usePageContext';
import performTracking from '../../../lib/utils/performTracking';
import { ANALYTICS_EVENTS } from '../../constants';
import StationContext from '../../context/station';
import BadgeModalContent from '../BadgeModalContent';
import BadgeWithModal from '../BadgeWithModal';

function BadgeAd({
  campaignId,
  imageUrl,
  title,
  description,
  callToAction,
  detailsImageUrl,
  targetUrl,
  impressionTrackingUrls,
  detailsImpressionTrackingUrls,
  clickTrackingUrl,
}) {
  const ref = useRef();
  const analytics = useAnalytics();
  const { pageName } = usePageContext();
  const stationId = useContext(StationContext);

  const isVisible = useInView(ref, {
    threshold: 1,
    once: true,
    rootMargin: '0px 0px -15% 0px',
  }, [ref]);

  const handleModalOpen = useCallback(() => {
    performTracking(detailsImpressionTrackingUrls);
  }, [detailsImpressionTrackingUrls]);

  const handleClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.BADGE_AD_OPENED,
      attributes: {
        Context: pageName,
        UISource: 'Web',
        Station_ID: stationId.toString(),
        Ad_ID: campaignId,
      },
    });
  }, [analytics, campaignId, pageName, stationId]);

  const handleCTAClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.BADGE_AD_CONTENT_CLICKED,
      attributes: {
        Context: pageName,
        UISource: 'Web',
        Station_ID: stationId.toString(),
        Ad_ID: campaignId,
      },
    });
  }, [analytics, campaignId, pageName, stationId]);

  useEffect(() => {
    if (isVisible) {
      performTracking(impressionTrackingUrls);
    }
  }, [isVisible, impressionTrackingUrls]);

  return (
    <div ref={ref}>
      <BadgeWithModal
        imageUrl={imageUrl}
        title={title}
        onClick={handleClick}
        onModalOpen={handleModalOpen}
      >
        {({ close }) => (
          <BadgeModalContent
            imageUrl={imageUrl}
            title={title}
            description={description}
            detailsImageUrl={detailsImageUrl}
            callToAction={callToAction}
            targetUrl={targetUrl}
            clickTrackingUrl={clickTrackingUrl}
            onCTAClick={(e) => {
              handleCTAClick(e);
              close();
            }}
          />
        )}
      </BadgeWithModal>
    </div>
  );
}

export default memo(BadgeAd, equal);

BadgeAd.propTypes = {
  callToAction: PropTypes.string,
  campaignId: PropTypes.string.isRequired,
  clickTrackingUrl: PropTypes.string,
  description: PropTypes.string,
  detailsImageUrl: PropTypes.string,
  detailsImpressionTrackingUrls: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.string.isRequired,
  impressionTrackingUrls: PropTypes.arrayOf(PropTypes.string),
  targetUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

BadgeAd.defaultProps = {
  callToAction: undefined,
  clickTrackingUrl: undefined,
  description: undefined,
  detailsImageUrl: undefined,
  detailsImpressionTrackingUrls: [],
  impressionTrackingUrls: [],
  targetUrl: undefined,
};
