import getFuelOption from '../utils/getFuelOption';
import usePreferredFuelType from './usePreferredFuelType';

/**
 * Attempts to identify a user's preferred fuel type by checking for a setting in local storage and
 * defaulting to Regular. The returned value is the fuel type ID.
 * @returns number An integer representing the fuel type
 */
export default function usePreferredFuelTypeID() {
  const lastFuelType = usePreferredFuelType();
  return getFuelOption(lastFuelType).value;
}
