import { Actions, Header, Link, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { noop } from '../../../lib/utils';
import sortFavoriteLists from '../../../lib/utils/sortFavoriteLists';
import { FavoriteListPropType } from '../../prop-types/favoriteList';
import FavoriteListsDropdown from '../FavoriteListsDropdown';
import styles from './FavoriteStationIcon.module.css';

const cx = classNames.bind(styles);

export default function AddStationToFavorites({
  favoriteLists,
  onAddStationToFavorites,
  onCancel,
  error,
}) {
  const [favoriteListId, setFavoriteListId] = useState(() => {
    const sortedLists = sortFavoriteLists(favoriteLists);
    return sortedLists.length && sortedLists[0].id;
  });

  const handleAddStationToFavorites = useCallback(() => {
    onAddStationToFavorites(favoriteListId);
  }, [favoriteListId, onAddStationToFavorites]);

  const handleFavoriteListChange = useCallback((list) => {
    if (list) {
      setFavoriteListId(list.id);
    }
  }, []);

  return (
    <Fragment>
      <Header as="h3">Choose a list to add this station to</Header>
      <FavoriteListsDropdown
        className={cx('favoriteListsDropdown')}
        value={favoriteListId}
        favoriteLists={favoriteLists}
        onChange={handleFavoriteListChange}
      />
      {!!error && (
        <Text as="p" color="orange">
          {error}
        </Text>
      )}
      <br />
      <Actions>
        <Actions.Button
          primary
          fluid
          onClick={handleAddStationToFavorites}
          disabled={!favoriteListId}
          desktop={6}
        >
          Add to List
        </Actions.Button>
        <Actions.Action>
          <Link uppercase onClick={onCancel}>
            Cancel
          </Link>
        </Actions.Action>
      </Actions>
    </Fragment>
  );
}

AddStationToFavorites.propTypes = {
  favoriteLists: PropTypes.arrayOf(FavoriteListPropType),
  onAddStationToFavorites: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

AddStationToFavorites.defaultProps = {
  favoriteLists: [],
  onAddStationToFavorites: noop,
  onCancel: noop,
  error: undefined,
};
