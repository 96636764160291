export const options = [
  {
    label: 'Bad',
    icon: '//static.gasbuddy.com/web/consumer/scores/Bad_Selected.svg',
    value: 1,
  },
  {
    label: 'Okay',
    icon: '//static.gasbuddy.com/web/consumer/scores/Okay_Selected.svg',
    value: 2,
  },
  {
    label: 'Great',
    icon: '//static.gasbuddy.com/web/consumer/scores/Great_Selected.svg',
    value: 3,
  },
];

export const quickReviewOptions = [
  {
    label: 'Bad',
    icon: '//static.gasbuddy.com/web/consumer/scores/Bad_Unselected.svg',
    activeIcon: '//static.gasbuddy.com/web/consumer/scores/Bad_Selected.svg',
    value: 1,
  },
  {
    label: 'Okay',
    icon: '//static.gasbuddy.com/web/consumer/scores/Okay_Unselected.svg',
    activeIcon: '//static.gasbuddy.com/web/consumer/scores/Okay_Selected.svg',
    value: 2,
  },
  {
    label: 'Great',
    icon: '//static.gasbuddy.com/web/consumer/scores/Great_Unselected.svg',
    activeIcon: '//static.gasbuddy.com/web/consumer/scores/Great_Selected.svg',
    value: 3,
  },
];

export const RATING_EXPERIENCE = {
  BAD: 1,
  OKAY: 2,
  GREAT: 3,
  NOT_SURE: 0,
};

export const REVIEWS_PAGE_SIZE = 10;
