import { FlexGrid, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import EmergencyStatusReport from '../../prop-types/emergency-status-report';
import ReportedBy from '../ReportedBy';
import styles from './OutageStatusBadges.module.css';

const cx = classnames.bind(styles);

export default function OutageStatusBadges({ powerReport, gasReport, dieselReport, sellsDiesel, iconsOnly }) {
  const hasPower = powerReport?.status;
  const hasGas = gasReport?.status;
  const hasDiesel = dieselReport?.status;

  const statuses = [
    {
      name: 'Power',
      iconSrc: `//static.gasbuddy.com/web/icons/${hasPower ? '' : 'no-'}power-circle.svg`,
      iconTitle: `Has${hasPower ? '' : ' No'} Power`,
      report: powerReport,
    },
    {
      name: 'Gas',
      iconSrc: `//static.gasbuddy.com/web/icons/${hasGas ? '' : 'no-'}gas-circle.svg`,
      iconTitle: `Has${hasGas ? '' : ' No'} Gas`,
      report: gasReport,
    },
  ];

  if (sellsDiesel) {
    statuses.push({
      name: 'Diesel',
      iconSrc: `//static.gasbuddy.com/web/icons/${hasDiesel ? '' : 'no-'}diesel-circle.svg`,
      iconTitle: `Has${hasDiesel ? '' : ' No'} Diesel`,
      report: dieselReport,
    });
  }

  return (
    <FlexGrid className={cx('statusList', { iconsOnly })}>
      {statuses.map(status => (
        <FlexGrid.Column key={status.name} className={cx('status')} mobile={4}>
          <img
            className={cx('statusIcon')}
            data-testid="statusIcon"
            src={status.iconSrc}
            alt=" "
            title={status.iconTitle}
          />
          {!iconsOnly && (
            <div className={cx('reportWrapper')}>
              <Text className={cx('title')} color="red">{status.name}</Text>
              <ReportedBy
                time={status.report?.timestamp}
                username={status.report?.username}
              />
            </div>
          )}
        </FlexGrid.Column>
      ))}
    </FlexGrid>
  );
}

OutageStatusBadges.propTypes = {
  powerReport: EmergencyStatusReport,
  gasReport: EmergencyStatusReport,
  dieselReport: EmergencyStatusReport,
  sellsDiesel: PropTypes.bool,
  iconsOnly: PropTypes.bool,
};

OutageStatusBadges.defaultProps = {
  powerReport: {
    status: true,
  },
  gasReport: {
    status: true,
  },
  dieselReport: undefined,
  sellsDiesel: false,
  iconsOnly: false,
};
