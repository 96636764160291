import { Header } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getCstoreBrandName, getFuelBrandName } from '../../../lib/utils/station';
import NoClickPropagation from '../NoClickPropagation';

export default function StationDisplayName({
  as,
  brands,
  headerBadge,
  headerClass,
  stationName,
  subheaderClass,
  to,
}) {
  const hasTwoBrands = brands?.length === 2;
  const [firstBrand, secondBrand] = hasTwoBrands ? brands : [null, null];
  const isSingleBranded = firstBrand && secondBrand && firstBrand.brandId === secondBrand.brandId;

  const Element = useMemo(() => (
    !!to ? Link : 'span' // eslint-disable-line no-extra-boolean-cast
  ), [to]);

  const headerProps = {
    ...(!!to && { to }),
    style: { color: 'inherit', fontWeight: 700, textDecoration: 'inherit' },
  };

  return hasTwoBrands && !isSingleBranded ? (
    <Fragment>
      <Header className={headerClass} as={as} color="midnight" snug>
        <NoClickPropagation as="span">
          <Element {...headerProps}>
            {getFuelBrandName(brands) || stationName}
          </Element>
        </NoClickPropagation>
        <span>&nbsp;{headerBadge}</span>
      </Header>
      <div className={subheaderClass}>
        {`& ${getCstoreBrandName(brands) || stationName}`}
      </div>
    </Fragment>
  ) : (
    <Header className={headerClass} as={as} color="midnight" snug>
      <Element {...headerProps}>
        {isSingleBranded && firstBrand.name ? firstBrand.name : stationName}
      </Element>
      <span>&nbsp;{headerBadge}</span>
    </Header>
  );
}

StationDisplayName.propTypes = {
  as: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.shape({})),
  headerBadge: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
  ]),
  headerClass: PropTypes.string,
  stationName: PropTypes.string.isRequired,
  subheaderClass: PropTypes.string,
  to: PropTypes.string,
};

StationDisplayName.defaultProps = {
  brands: [],
  headerBadge: false,
  headerClass: undefined,
  subheaderClass: undefined,
  to: undefined,
};
