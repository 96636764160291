import chunk from 'lodash.chunk';
import difference from 'lodash.difference';
import { useEffect, useState } from 'react';
import { ANALYTICS_EVENTS } from '../../common/constants';
import useAnalytics from './useAnalytics';

function createImpressionSignature(impression) {
  return `${impression.Station_Id}-${impression.List_Ad_Id}`;
}

export default function useBadgeAdImpressions(impressions) {
  const [loggedImpressionSignatures, setLoggedImpressionSignatures] = useState([]);
  const analytics = useAnalytics();

  useEffect(() => {
    // Build the signature list to compare to the logged list
    const impressionSignatures = impressions.map(createImpressionSignature);
    const newImpressions = difference(impressionSignatures, loggedImpressionSignatures);

    // Record badge ad impressions if new badge ads provided
    if (newImpressions.length) {
      // Never attach more than 44 impressions to an event
      chunk(impressions, 44).forEach((impressionsChunk) => {
        const attributes = {
          UISource: 'Web',
          Context: 'Station Search Page',
        };

        impressionsChunk.forEach((impression, index) => {
          attributes[index] = impression;
        });

        analytics.tagEvent({ name: ANALYTICS_EVENTS.BADGE_AD_IMPRESSIONS, attributes });
      });
      setLoggedImpressionSignatures(impressionSignatures);
    }
  }, [analytics, impressions, loggedImpressionSignatures]);
}
