// images.gasbuddy.io and images.stage,gasbuddy.io offer dynamic image resizing server-side through lambda
// Base images like https://images.gasbuddy.io/b/100.png can be dynamically image resized to produce optimized images using images.gasbuddy.io
export default function getOptimizedImageUrl(url, height = 'auto', width = 'auto') {
  // The url could be already optimized if it has these type of tokens: /99xauto/, /autox99/, /autoxauto/, /99x99/
  const alreadyOptimized = /\/([0-9]*|auto)x([0-9]*|auto)\//g.test(url);

  const canBeResized = (url.indexOf('images.gasbuddy.io') > 0 || url.indexOf('images.stage.gasbuddy.io') > 0) && !alreadyOptimized;

  if (canBeResized) {
    const tokens = url.split('/');
    const [protocol, emptyToken, host, ...rest] = tokens;
    const optimizedImageUrl = [protocol, emptyToken, host].concat(`${height}x${width}`, ...rest).join('/');
    return optimizedImageUrl;
  }

  return url;
}
