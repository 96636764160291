import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import BadgeAd from '../BadgeAd';
import NoClickPropagation from '../NoClickPropagation';
import styles from './BadgeAds.module.css';
import BadgeAdPropType from '../../prop-types/badge';

const cx = classnames.bind(styles);

export default function BadgeAds({
  badges,
  isPayAvailable,
}) {
  const finalBadges = [...(badges || [])];

  if (isPayAvailable === false) {
    finalBadges.push({
      badgeId: 'badge_no_pay_available',
      callToAction: 'Got It',
      campaignId: '0',
      clickTrackingUrl: null,
      description: 'This station does not support you saving up to 25¢/gal with our Pay with GasBuddy card. However, there are thousands of stations that do! Look for stations without this badge and you’ll save on every gallon.',
      detailsImageUrl: null,
      detailsImpressionTrackingUrls: [],
      imageUrl: 'https://static.gasbuddy.com/web/pay/no-pwgb-support.png',
      impressionTrackingUrls: [],
      internalName: 'Fake Badge Ad To Flag Stations That Don\'t Support Pay with GasBuddy',
      targetUrl: null,
      title: 'This station does not accept Pay with GasBuddy',
    });
  }

  return finalBadges && (
    <div className={cx('badgeAds')}>
      {finalBadges.filter(Boolean).map(badge => (
        <NoClickPropagation key={badge.badgeId} className={cx('badgeAd')}>
          <BadgeAd {...badge} />
        </NoClickPropagation>
      ))}
    </div>
  );
}

BadgeAds.propTypes = {
  badges: PropTypes.arrayOf(BadgeAdPropType),
  isPayAvailable: PropTypes.bool,
};

BadgeAds.defaultProps = {
  badges: [],
  isPayAvailable: undefined,
};
