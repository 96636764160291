import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text, Header, Actions, Link } from '@gasbuddy/react-components';
import { noop } from '../../../lib/utils';

export default function DeleteStationFromFavorites({
  favoriteListName,
  onDeleteFromFavorites,
  onCancel,
  error,
}) {
  return (
    <Fragment>
      <Header as="h3">Remove this station from your favorites?</Header>
      <Text as="p">
        Are you sure you want to remove this station from your <strong>&quot;{favoriteListName}&quot;</strong> favorite list?
      </Text>
      {!!error && (
        <Text as="p" color="orange">
          {error}
        </Text>
      )}
      <br />
      <Actions>
        <Actions.Button
          primary
          onClick={onDeleteFromFavorites}
          desktop={6}
        >
          Remove Station
        </Actions.Button>
        <Actions.Action>
          <Link uppercase onClick={onCancel}>
            Cancel
          </Link>
        </Actions.Action>
      </Actions>
    </Fragment>
  );
}

DeleteStationFromFavorites.propTypes = {
  favoriteListName: PropTypes.string.isRequired,
  onDeleteFromFavorites: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.string,
};

DeleteStationFromFavorites.defaultProps = {
  onDeleteFromFavorites: noop,
  onCancel: noop,
  error: undefined,
};
