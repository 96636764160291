import PropTypes from 'prop-types';

const AddressPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string,
  locality: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  crossStreet: PropTypes.string,
  crossStreetAlias: PropTypes.string,
  addressAlias: PropTypes.string,
  atIntersection: PropTypes.bool,
  nearbyStreet: PropTypes.string,
  description: PropTypes.string,
});

export const AddressFormPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  locality: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  crossStreet: PropTypes.string,
  crossStreetAlias: PropTypes.string,
  addressAlias: PropTypes.string,
  atIntersection: PropTypes.bool,
  nearbyStreet: PropTypes.string,
  description: PropTypes.string,
});

export default AddressPropType;
