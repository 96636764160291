import { Text } from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import classnames from 'classnames/bind';
import hdate from 'human-date';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { getMemberPriceStampInfoFromMemberName } from '../../../lib/utils';
import { isValidFormattedPrice } from '../../../lib/utils/prices';
import { OUTDATED_PRICE_DISPLAY } from '../../constants';
import styles from './StationOfferPrice.module.css';

const cx = classnames.bind(styles);

export default function StationOfferPrice({
  asCard,
  largeStyles,
  payPrice,
  regularPriceReport,
  showPriceTab,
  smallMobilePrice,
}) {
  const regularPriceStampInfo = regularPriceReport ? getMemberPriceStampInfoFromMemberName(regularPriceReport.reportedBy) : {};
  const showCreditPriceInfo = isValidFormattedPrice(payPrice) && isValidFormattedPrice(regularPriceReport?.formattedPrice);
  const { mobile: isMobileView } = useWindowSize();

  return (
    <div className={cx('offerContainer', { asCard: isMobileView || asCard })}>
      <Text bold size="xl" className={cx('payPrice', { smallMobilePrice })}>
        {payPrice || OUTDATED_PRICE_DISPLAY}
      </Text>
      {showCreditPriceInfo && (
        <Fragment>
          <Text className={cx('reportedBy')}>
            <img
              src={regularPriceStampInfo.iconPath}
              alt={`${regularPriceReport.reportedBy} Member Icon`}
              title={`Reported By: ${regularPriceReport.reportedBy}`}
            />
            &nbsp;
            <Text color="midnight" className={cx('regularPrice', { smallMobilePrice })}>
              {regularPriceReport.formattedPrice}
            </Text>
          </Text>
          <Text as="p" className={cx('reportedTime', { large: largeStyles })} size="sm">
            {hdate.relativeTime(regularPriceReport.postedTime)}
          </Text>
        </Fragment>
      )}
      {showPriceTab && (
        <Text className={cx('creditTag')}>CREDIT</Text>
      )}
    </div>
  );
}

StationOfferPrice.propTypes = {
  asCard: PropTypes.bool,
  largeStyles: PropTypes.bool,
  payPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regularPriceReport: PropTypes.shape({
    formattedPrice: PropTypes.string.isRequired,
    postedTime: PropTypes.string,
    reportedBy: PropTypes.string,
  }),
  showPriceTab: PropTypes.bool,
  smallMobilePrice: PropTypes.bool,
};

StationOfferPrice.defaultProps = {
  asCard: false,
  largeStyles: false,
  payPrice: undefined,
  regularPriceReport: undefined,
  showPriceTab: false,
  smallMobilePrice: false,
};
