import { Fragment } from 'react';
import { Text } from '@gasbuddy/react-components';
import BadgeWithModal from '../BadgeWithModal';
import BadgeModalContent from '../BadgeModalContent';

export default function EnterpriseBadge() {
  const imageUrl = '//static.gasbuddy.com/web/consumer/Verified_Icon.svg';
  const title = 'This station is verified!';

  return (
    <BadgeWithModal
      imageUrl={imageUrl}
      title={title}
    >
      <BadgeModalContent
        imageUrl={imageUrl}
        title={title}
        description={(
          <Fragment>
            <Text>
              The information for this station is being monitored by the station management.
            </Text>
            <br /><br />
            <Text>
              This station is actively engaged with our users and uses the information provided to improve the station experience.
            </Text>
          </Fragment>
        )}
      />
    </BadgeWithModal>
  );
}
