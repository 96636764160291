import { Button, Header, Image, Text } from '@gasbuddy/react-components';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { noop } from '../../../lib/utils';
import BadgeAdImage from '../BadgeImage';

export default function BadgeModalContent({
  callToAction,
  clickTrackingUrl,
  description,
  detailsImageUrl,
  imageUrl,
  onCTAClick,
  targetUrl,
  title,
}) {
  const handleCtaClick = useCallback(() => {
    onCTAClick();

    if (clickTrackingUrl) {
      axios.get(clickTrackingUrl);
    }
  }, [onCTAClick, clickTrackingUrl]);

  let formattedTargetUrl;
  // keep targetUrl as undefined if it isn't available
  if (targetUrl) {
    formattedTargetUrl = targetUrl.startsWith('http://') ? targetUrl.replace(/http:\/\//, '') : targetUrl;
    formattedTargetUrl = formattedTargetUrl.startsWith('https://') ? formattedTargetUrl : `https://${formattedTargetUrl}`;
  }

  return (
    <Fragment>
      <Header as="h3">{title}</Header>
      <br />
      <Text as="p" centered>
        <BadgeAdImage src={imageUrl} alt={`Heading image for ${title}`} large />
      </Text>
      <br />
      <br />
      {!!description && (
        <Text as="p">{description}</Text>
      )}
      {!!detailsImageUrl && (
        <Fragment>
          <Image src={detailsImageUrl} alt={`Additional descriptive image for ${title}`} />
          <br />
        </Fragment>
      )}
      {callToAction && (
        <Button
          primary
          as={targetUrl ? 'a' : 'button'}
          href={formattedTargetUrl}
          target="_blank"
          onClick={handleCtaClick}
        >
          {callToAction}
        </Button>
      )}
    </Fragment>
  );
}

BadgeModalContent.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.node,
  detailsImageUrl: PropTypes.string,
  callToAction: PropTypes.string,
  targetUrl: PropTypes.string,
  clickTrackingUrl: PropTypes.string,
  onCTAClick: PropTypes.func,
};

BadgeModalContent.defaultProps = {
  imageUrl: undefined,
  title: undefined,
  description: undefined,
  detailsImageUrl: undefined,
  callToAction: undefined,
  targetUrl: undefined,
  clickTrackingUrl: undefined,
  onCTAClick: noop,
};

BadgeModalContent.displayName = 'BadgeModalContent';
