import { useState, useCallback, useEffect } from 'react';

export default function useInView(ref, { once, root, rootMargin, threshold } = {}) {
  const [isInViewport, setIsInView] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState(typeof document !== 'undefined' && !document.hidden);
  const [isActiveWindow, setIsActiveWindow] = useState(typeof document !== 'undefined' && document.hasFocus());
  const isInView = isInViewport && isActiveWindow && isActiveTab;
  const expired = once && isInView;

  const handler = useCallback((changes) => {
    changes.forEach((change) => {
      if (change.isIntersecting) {
        setIsInView(true);
      } else {
        setIsInView(false);
      }
    });
  }, []);

  useEffect(() => {
    const el = ref.current;

    if (el && typeof IntersectionObserver !== 'undefined' && !expired) {
      const observer = new IntersectionObserver(handler, {
        root, // Defaults to the browser viewport if not specified or if null
        rootMargin, // Defaults to all zeros
        threshold, // Defaults to 0
      });
      observer.observe(el);

      return () => {
        observer.unobserve(el);
      };
    }

    return undefined;
  }, [expired, handler, ref, root, rootMargin, threshold]);

  // Update isInView when the active tab changes
  useEffect(() => {
    function handleVisibilityChange() {
      if (document.hidden) {
        setIsActiveTab(false);
      } else {
        setIsActiveTab(true);
      }
    }

    // Only listen to changes if the subscriber's request has not expired
    if (!expired) {
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }

    return undefined;
  }, [expired]);

  // Update isInView when the window focuses/blurs
  useEffect(() => {
    function handleBlur() {
      setIsActiveWindow(false);
    }

    function handleFocus() {
      setIsActiveWindow(true);
    }

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return isInView;
}
