import { useLocalStorage } from '@gasbuddy/react-hooks';
import { useMemo } from 'react';

const DAYS_BETWEEN_SHOWING_MODAL = 20;

export default function useMemberNameModalAvailability() {
  const [lastShownMemberNameModal, setLastShownMemberNameModal] = useLocalStorage('lastShownMemberNameModal', 'none');
  const shouldShowMemberNameModal = useMemo(() => {
    if (lastShownMemberNameModal === 'none') {
      return true;
    }

    const lastShownDate = new Date(lastShownMemberNameModal);
    const msDifference = new Date().getTime() - lastShownDate.getTime();
    const dayDifference = Math.floor(msDifference / (1000 * 60 * 60 * 24));
    return dayDifference >= DAYS_BETWEEN_SHOWING_MODAL;
  }, [lastShownMemberNameModal]);

  return [shouldShowMemberNameModal, setLastShownMemberNameModal];
}
