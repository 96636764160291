import PropTypes from 'prop-types';

export default PropTypes.shape({
  badgeId: PropTypes.string.isRequired,
  callToAction: PropTypes.string,
  clickTrackingUrl: PropTypes.string,
  description: PropTypes.string,
  detailsImageUrl: PropTypes.string,
  detailsImpressionTrackingUrls: PropTypes.arrayOf(PropTypes.string),
  imageUrl: PropTypes.string.isRequired,
  impressionTrackingUrls: PropTypes.arrayOf(PropTypes.string),
  targetUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
});
