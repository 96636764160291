export default function getRegionalGasPricesAreaCriteria(area, countyId) {
  const criteria = {
    location_type: 'region',
  };

  if (countyId) {
    criteria.location_type = 'county';
  } else if (area) {
    criteria.location_type = ['locality', 'metro'];
  }

  return criteria;
}
