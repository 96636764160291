import { useCallback, useEffect, useState } from 'react';

export default function useDelayedCallback(func, delay) {
  const [triggered, setIsTriggered] = useState(false);

  const trigger = useCallback(() => {
    setIsTriggered(true);
  }, []);

  useEffect(() => {
    let timeout;

    if (triggered) {
      timeout = setTimeout(() => {
        func();
        setIsTriggered(false);
        clearTimeout(timeout);
      }, delay);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay, func, triggered]);

  return trigger;
}
