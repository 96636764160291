import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Text } from '@gasbuddy/react-components';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../Breadcrumb';

export default function NoAustralia() {
  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container>
        <Breadcrumb
          linkTo="/"
          showSeparator={false}
        >
          <Fragment>
            <FontAwesomeIcon icon={faHome} />
            <Text>
              &nbsp;Home
            </Text>
          </Fragment>
        </Breadcrumb>
        <Breadcrumb
          linkTo="/home"
          showSeparator
        >
          Gas Price Search
        </Breadcrumb>
        <br /><br />
        <Text as="p" color="midnight">G’day, Mate!</Text>
        <Text as="p" color="midnight">
          Thanks for popping around. Unfortunately, GasBuddy is no longer operating in Australia.
        </Text>
      </Container>
    </Fragment>
  );
}
