import { getDateDiffInSeconds } from '.';
import { FUEL_ID_TO_POI_VALUE, OUTDATED_PRICE_DISPLAY } from '../../common/constants';

const EMPTY_PRICE_REPORT = {
  price: 0,
  formattedPrice: OUTDATED_PRICE_DISPLAY,
};

export const isValidPrice = priceReport => (priceReport.price ? priceReport.price > 0 : false);
export const isValidFormattedPrice = (price) => {
  if (!price) {
    return false;
  }

  // Assumes price has been formatted to either be a proper price string or the outdated price display
  return price !== OUTDATED_PRICE_DISPLAY;
};

/**
 * Determines whether the given price report is old in comparison to the maxAge filter.
 * If maxAge is 0 or undefined, the price is automatically returned as not old.
 * If the priceReport has no postedTime, the price is automatically returned as old.
 * @param {PricePropType} pricePostedTime A price's posted time with which to determine if the price is old
 * @param {number} maxAge The maximum age, in hours, a price can be before it is considered old
 */
export const isOldPrice = (pricePostedTime, maxAge) => {
  if (!maxAge) {
    return false;
  }

  if (!pricePostedTime) {
    return true;
  }

  const dateDiffInSeconds = getDateDiffInSeconds(pricePostedTime);
  return (dateDiffInSeconds / (60 * 60) > maxAge);
};

/**
 * Gets the price from a list of price reports to display.
 * If the price is invalid, an empty price will be returned.
 * If the price is too old (as determined with the maxAge criteria parameter), a null price will be returned.
 * @param {arrayOf(object)} priceReports A list of price reports for a station to find the correct display price for
 * @param {object} criteria A list of criteria, such as fuelType, maxAge, and includeCash to filter prices by
 */
export const getDisplayPriceFromPriceReportList = (priceReports = [], criteria = {}) => {
  const { fuelType = 1, maxAge = 0, includeCash = true } = criteria;

  const fuelTypeReport = priceReports.find(report => report.fuelProduct === FUEL_ID_TO_POI_VALUE[fuelType]);

  if (!fuelTypeReport) {
    return EMPTY_PRICE_REPORT;
  }

  const { cash: cashPrice, credit: creditPrice } = fuelTypeReport;
  const prices = [];

  if (fuelTypeReport.credit) {
    prices.push({
      isCash: false,
      postedTime: creditPrice.postedTime,
      price: creditPrice.price,
      formattedPrice: creditPrice.formattedPrice,
      reportedBy: creditPrice.nickname,
    });
  }

  if (includeCash && cashPrice) {
    prices.push({
      isCash: true,
      postedTime: cashPrice.postedTime,
      price: cashPrice.price,
      formattedPrice: cashPrice.formattedPrice,
      reportedBy: cashPrice.nickname,
    });
  }

  const orderedPrices = prices
    .filter(fuelPrice => fuelPrice?.price)
    .filter(fuelPrice => !isOldPrice(fuelPrice.postedTime, maxAge))
    .sort((a, b) => a.price - b.price);

  if (!orderedPrices.length && maxAge) {
    return null;
  }

  return orderedPrices[0] || EMPTY_PRICE_REPORT;
};
