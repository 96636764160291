import { useMemo } from 'react';
import { BANNER_ADS } from '../../common/constants';

export default function useBannerAds(desktopSlotName, mobileSlotName) {
  return useMemo(() => [
    {
      ...BANNER_ADS[0],
      path: desktopSlotName,
    },
    {
      ...BANNER_ADS[1],
      path: mobileSlotName,
    },
  ], [desktopSlotName, mobileSlotName]);
}
