function isResolution(obj) {
  return (typeof obj === 'string' && obj === 'fluid')
    || (Array.isArray(obj) && obj.length === 1 && isResolution(obj[0]))
    || (Array.isArray(obj) && obj.length === 2 && obj.every(Number.isInteger));
}

function isSize(obj) {
  return Array.isArray(obj) && obj.every(isResolution);
}

export default function isSizeMapping(obj) {
  return Array.isArray(obj) && obj.length === 2 && isResolution(obj[0]) && isSize(obj[1]);
}
