const ERROR_CODES = {
  BAD_REQUEST: 400,
};

export class BadRequestError extends Error {
  constructor(message = 'Bad Request', status = ERROR_CODES.BAD_REQUEST) {
    super(message);
    this.domain = 'consumer-web';
    this.name = this.constructor.name;
    Error.captureStackTrace(this, BadRequestError);
    this.status = status;
  }
}

export class InvalidParamError extends BadRequestError {
  constructor(message = 'Invalid Param Supplied', status) {
    super(message, status);
    Error.captureStackTrace(this, InvalidParamError);
  }
}
