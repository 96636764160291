import { useMemo } from 'react';
import { FUEL_ID_TO_POI_VALUE } from '../../common/constants';
import { flattenReducer, priceFormatter } from '../utils';

export default function useDiscountedPrice(offers, price, priceUnit, fuelType = 1) {
  return useMemo(() => {
    if (!price) {
      return undefined;
    }

    const grade = FUEL_ID_TO_POI_VALUE[fuelType];
    const discounts = (offers || []).map(o => o.discounts).reduce(flattenReducer, []);
    const matchedDiscount = discounts.find(d => d.grades.includes(grade));
    const discountToUse = matchedDiscount?.pwgbDiscount || matchedDiscount?.receiptDiscount;

    if (discountToUse) {
      return priceFormatter(price - parseFloat(discountToUse), priceUnit);
    }

    return undefined;
  }, [fuelType, offers, price, priceUnit]);
}
