import { FUEL_TYPES } from '../../common/constants';
import { getDisplayPriceFromPriceReportList } from './prices';

// Filter through the stations to make sure they don't have old prices, if we care about that.
// If the station already has a prices object, then use that before retrieving prices from the fuels array
// Afterwards, add the display price and whether it sells diesel to each station.
export default function buildStationWithDisplayPrice({
  stations,
  maxAge = undefined,
  includeCash = true,
  fuelType,
}) {
  return stations.reduce((stationList, station) => {
    let displayPrice;
    let sellsDiesel;

    // TODO: sellsDiesel should be calculated server-side and displayPrice should be replaced with formattedPrice
    if (station.prices) {
      displayPrice = getDisplayPriceFromPriceReportList(station.prices, { fuelType, maxAge, includeCash });
      sellsDiesel = station.fuels.some(fuel => fuel === FUEL_TYPES.DIESEL.toLowerCase());
    }

    if (displayPrice !== null) {
      stationList.push({ ...station, displayPrice, sellsDiesel });
    }

    return stationList;
  }, []);
}
