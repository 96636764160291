import { faHeart as faHeartEmpty } from '@fortawesome/pro-light-svg-icons/faHeart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragAndDrop, Loader, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { noop } from '../../../lib/utils';
import getDraggableItemSchema from '../../../lib/utils/getDraggableItemSchema';
import FuelTypePropType from '../../prop-types/fuelType';
import StationPropType from '../../prop-types/station';
import GenericStationListItem from '../GenericStationListItem/index';
import StationPriceReport from '../StationPriceReport';
import styles from './FavoriteStations.module.css';

const cx = classnames.bind(styles);

export default function FavoriteStations({
  fuelType,
  isEditable,
  loading,
  onPricesPosted,
  onReorder,
  stations,
}) {
  const [isShowingPriceReportModal, setIsShowingPriceReportModal] = useState(false);
  const [stationClicked, setStationClicked] = useState();

  const handlePriceClicked = useCallback((e, station) => {
    if (station.fuels?.length > 0) {
      e.stopPropagation(); // ensure we show the modal and not link to station details page
      setStationClicked(station);
      setIsShowingPriceReportModal(true);
    }
  }, []);

  const closePriceReportModal = useCallback(() => {
    setStationClicked(null);
    setIsShowingPriceReportModal(false);
  }, []);

  const handlePricesPosted = useCallback(() => {
    closePriceReportModal();
    onPricesPosted();
  }, [closePriceReportModal, onPricesPosted]);

  if (loading) {
    return (
      <Loader size="lg">
        Loading your favorite stations...
      </Loader>
    );
  }

  if (stations.length === 0) {
    return (
      <Text color="midnight" className={cx('noStationsMessage')}>
        You have no stations favorited in this list.
        <br />
        <br />
        Click the <FontAwesomeIcon icon={faHeartEmpty} size="2x" className={cx('heartSymbol')} /> symbol when viewing a station to add it to your favorites.
      </Text>
    );
  }

  const stationItems = [];
  if (stations.length) {
    stations.forEach((station) => {
      const favoriteStation = (
        <GenericStationListItem
          key={station.id}
          fuelType={fuelType}
          hideDeals={isEditable}
          onPriceClicked={(e) => { handlePriceClicked(e, station); }}
          shouldVisitStation={!isEditable}
          showFavoriting={isEditable}
          station={station}
        />
      );

      if (isEditable) {
        stationItems.push(getDraggableItemSchema(station.id, favoriteStation));
      } else {
        stationItems.push(favoriteStation);
      }
    });
  }

  return (
    <>
      {!!stationClicked && (
        <StationPriceReport
          hidePriceReportButton
          modalOpen={isShowingPriceReportModal}
          onCancel={closePriceReportModal}
          onReportDone={handlePricesPosted}
          station={stationClicked}
        />
      )}
      {isEditable ? (
        <DragAndDrop
          groupName="favoriteStations"
          items={stationItems}
          onFinishedDragging={onReorder}
        />
      ) : stationItems}
    </>
  );
}

FavoriteStations.propTypes = {
  fuelType: FuelTypePropType,
  isEditable: PropTypes.bool,
  loading: PropTypes.bool,
  onPricesPosted: PropTypes.func,
  onReorder: PropTypes.func,
  stations: PropTypes.arrayOf(StationPropType),
};

FavoriteStations.defaultProps = {
  fuelType: undefined,
  isEditable: false,
  loading: false,
  onPricesPosted: noop,
  onReorder: noop,
  stations: [],
};
