import { StationMapPin } from '@gasbuddy/react-consumer-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import useDelayedCallback from '../../../lib/hooks/useDelayedCallback';
import { DEFAULT_STATION_LOGO, OUTDATED_PRICE_DISPLAY } from '../../constants';
import PriceUnits from '../../constants/priceUnits';

const scrollToElement = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

export default function StationPin({ displayPrice, id, lat, lng, logo, onClick, priceUnit, ...rest }) {
  const { mobile } = useWindowSize();

  const afterContainerClose = useDelayedCallback(() => {
    if (!mobile) {
      scrollToElement(id);
    }
  }, 500); // expandable container takes 250ms to expand/collapse

  const onPinClick = useCallback(() => {
    onClick(id);
    afterContainerClose();
  }, [afterContainerClose, id, onClick]);

  // Remove unused props given by Google API
  const { $prerender, $onMouseAllow, $geoService, $dimensionKey, $getDimensions, ...usedProps } = rest;

  return (
    <StationMapPin
      key={id}
      alt="station logo"
      lat={lat}
      lng={lng}
      logo={logo || DEFAULT_STATION_LOGO}
      onClick={onPinClick}
      price={displayPrice.formattedPrice}
      {...usedProps}
    />
  );
}

StationPin.propTypes = {
  displayPrice: PropTypes.shape({
    formattedPrice: PropTypes.string,
  }),
  id: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  logo: PropTypes.string,
  onClick: PropTypes.func,
  priceUnit: PropTypes.oneOf(Object.values(PriceUnits)),
};

StationPin.defaultProps = {
  displayPrice: {
    formattedPrice: OUTDATED_PRICE_DISPLAY,
  },
  id: undefined,
  lat: undefined,
  lng: undefined,
  logo: undefined,
  onClick: () => { },
  priceUnit: undefined,
};
