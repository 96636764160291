import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { AdParams, AdSizeMappingPropType, AdSizesPropType } from '../../prop-types/ad';
import styles from './Ad.modules.css';
import AdSlot from './AdSlot';

const cx = classnames.bind(styles);

export default function AdContainer({
  center,
  className,
  collapseIfEmpty,
  id,
  params,
  path,
  sizeMapping,
  sizes,
  ...rest
}) {
  return (
    <div
      className={cx('adContainer', { center }, className)}
      {...rest}
    >
      <AdSlot
        id={id}
        adUnit={path}
        sizes={sizes}
        sizeMapping={sizeMapping}
        params={params}
        collapseIfEmpty={collapseIfEmpty}
      />
    </div>
  );
}

AdContainer.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  collapseIfEmpty: PropTypes.bool,
  id: PropTypes.string,
  params: AdParams,
  path: PropTypes.string.isRequired,
  sizeMapping: AdSizeMappingPropType,
  sizes: AdSizesPropType.isRequired,
};

AdContainer.defaultProps = {
  center: false,
  className: undefined,
  collapseIfEmpty: true,
  id: undefined,
  params: undefined,
  sizeMapping: undefined,
};
