import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { faMap } from '@fortawesome/free-regular-svg-icons/faMap';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  FlexGrid,
  GoogleMap,
  Header,
  Image,
  Loader,
  Panel,
  Text,
  Tray,
  Visibility,
} from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks';
import classnames from 'classnames/bind';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useParams } from 'react-router-dom';
import store from 'store';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useBannerAds from '../../../lib/hooks/useBannerAds';
import useConfig from '../../../lib/hooks/useConfig';
import useFeatureGatedSlashPricing from '../../../lib/hooks/useFeaturedGatedSlashPricing';
import usePreferredFuelTypeID from '../../../lib/hooks/usePreferredFuelTypeID';
import useProfile from '../../../lib/hooks/useProfile';
import { canonicalizeString, pluralizeIfNeeded, scrollToTop } from '../../../lib/utils';
import { generateBreadcrumbSchema } from '../../../lib/utils/generateBreadcrumbSchema';
import { generateRegionalStationsSchema } from '../../../lib/utils/generateRegionalStationsSchema';
import getFuelOption from '../../../lib/utils/getFuelOption';
import getRegionalGasPricesAreaCriteria from '../../../lib/utils/getRegionalGasPricesAreaCriteria';
import getRegionByCodes from '../../../lib/utils/getRegionByCodes';
import getRegionByName from '../../../lib/utils/getRegionByName';
import { getDisplayPriceFromPriceReportList } from '../../../lib/utils/prices';
import createSortByProperty from '../../../lib/utils/sortByProperty';
import { ANALYTICS_EVENTS, AUSTRALIA_COUNTRY_CODE, FUEL_OPTIONS } from '../../constants';
import { InvalidParamError } from '../../constants/errors';
import StationsMapContext from '../../context/selected-station-map';
import { StaticContextPropType } from '../../prop-types/context';
import AreaCountyList from '../AreaCountyList';
import Breadcrumb from '../Breadcrumb';
import GenericStationList from '../GenericStationList';
import NoAustralia from '../NoAustralia';
import Page from '../Page';
import RegionAds from '../RegionAds';
import RegionContainer from '../RegionContainer';
import StationDetailBelt from '../StationDetailBelt';
import styles from './RegionalGasPrices.module.css';
import StationPin from './StationPin';
import useParsedLocalStorage from '../../../lib/hooks/useParsedLocalStorage';

const cx = classnames.bind(styles);

const pageName = 'Local_Listings';

const loaderStyles = {
  margin: '50px auto',
};

const getFuelPrice = (station) => {
  const fuelPrices = station.prices[0];
  return {
    price: fuelPrices?.cash?.price || fuelPrices?.credit?.price,
    formattedPrice: fuelPrices?.cash?.formattedPrice || fuelPrices?.credit?.formattedPrice,
    time: fuelPrices?.cash?.price ? fuelPrices.cash.postedTime : fuelPrices?.credit?.postedTime,
  };
};

// ensure our stations are properly ordered from lowest to highest current price
const stationSort = (a, b) => {
  const { price: minPriceA, time: priceATime } = getFuelPrice(a);
  const { price: minPriceB, time: priceBTime } = getFuelPrice(b);

  // ensure empty prices are always after non-empty prices
  if (!minPriceA) {
    return 1;
  }
  if (!minPriceB) {
    return -1;
  }

  // display lower price first
  if (minPriceA < minPriceB) {
    return -1;
  }

  if (minPriceA > minPriceB) {
    return 1;
  }

  // If prices are the same, show verified station first
  if (a.enterprise && !b.enterprise) {
    return -1;
  }

  if (b.enterprise && !a.enterprise) {
    return 1;
  }

  // If prices are same and stations have same verified state, put station with most recent price first
  if (priceATime > priceBTime) {
    return -1;
  }

  if (priceATime < priceBTime) {
    return 1;
  }

  // Both stations are equal in placement
  return 0;
};

const isLocationInfoError = ({ graphQLErrors }) => graphQLErrors?.map(error => error.extensions?.type).includes('LOCATION_INFO');

const getRegionNameByCode = (regionCode, countryCode) => getRegionByCodes(regionCode, countryCode).name;

const optionalPathFragment = resource => (resource ? `/${resource}` : '');

const createAreaListItem = (regionCode, countryCode, area, countyId, currentCounty) => {
  const targetUrl = `/gasprices/${getRegionNameByCode(regionCode, countryCode)}${optionalPathFragment(area)}${optionalPathFragment(countyId)}`;
  const { id, name } = currentCounty;

  return {
    displayText: countyId ? `${area} County` : area,
    regionCode,
    url: id && name
      ? {
        pathname: canonicalizeString(targetUrl),
        state: { previousCountyId: id, previousCountyName: name },
      }
      : targetUrl,
  };
};

const createAreaListItems = (areas = [], currentCounty = {}) => areas?.slice().sort(createSortByProperty('displayName'))
  .filter(area => !!area.regionCode)
  .map(area => createAreaListItem(area.regionCode, area.countryCode, area.displayName, area.legacyId, currentCounty));

const JSX_FUEL_OPTIONS = FUEL_OPTIONS.map(option => ({
  ...option,
  render: label => (
    <Text>
      {label}
      <Text className={cx('hiddenOption', 'visibleSelected')}>
        &nbsp;Fuel
        <Text className={cx('visibleDesktop')}>
          &nbsp;Prices
        </Text>
      </Text>
    </Text>
  ),
}));

export const GET_LOCATION = gql`
  query LocationByArea(
    $area: String,
    $countryCode: String,
    $criteria: Criteria,
    $fuel: Int,
    $regionCode: String,
  ) {
    locationByArea(area: $area, countryCode: $countryCode, criteria: $criteria, regionCode: $regionCode) {
      counties {
        countryCode
        displayName
        legacyId
        regionCode
      }
      displayName
      locationType
      localities {
        countryCode
        displayName
        regionCode
      }
      metros {
        countryCode
        displayName
        regionCode
      }
      stations(fuel: $fuel) {
        results {
          address {
            country
            line1
            line2
            locality
            postalCode
            region
          }
          amenities {
            amenityId
            name
            imageUrl
          }
          badges {
            badgeId
            callToAction
            campaignId
            clickTrackingUrl
            description
            detailsImageUrl
            detailsImpressionTrackingUrls
            imageUrl
            impressionTrackingUrls
            internalName
            targetUrl
            title
          }
          brands {
            brandId
            brandingType
            imageUrl
            name
          }
          emergencyStatus {
            hasDiesel {
              nickname
              reportStatus
              updateDate
            }
            hasGas {
              nickname
              reportStatus
              updateDate
            }
            hasPower {
              nickname
              reportStatus
              updateDate
            }
          }
          enterprise
          fuels
          hasActiveOutage
          id
          latitude
          longitude
          name
          payStatus {
            isPayAvailable
          }
          prices(fuel: $fuel) {
            cash {
              nickname
              postedTime
              price
              formattedPrice
            }
            credit {
              nickname
              postedTime
              price
              formattedPrice
            }
            discount
            fuelProduct
          }
          priceUnit
          ratingsCount
          reviews(limit: 1) {
            results {
              agreeTotal
              canDelete
              hasAgreed
              isReportable
              isVisible
              memberId
              overallRating
              profileImageUrl
              replyRequested
              review
              reviewId
              reviewDate
              sentimentScore
            }
          }
          starRating
          offers {
            discounts {
              grades
              highlight
              pwgbDiscount
              receiptDiscount
            }
            highlight
            id
            types
            use
          }
        }
      }
    }
  }
`;

export default function RegionalGasPrices({ staticContext }) {
  const {
    area,
    countyId: countyIdFromUrl,
    region: regionUrlName,
  } = useParams();
  const history = useHistory();
  const countyId = countyIdFromUrl && parseInt(countyIdFromUrl, 10);
  const fuelType = usePreferredFuelTypeID();
  const { googleMapsKey: mapKey } = useConfig();
  const [showMap, setShowMap] = useState(false);
  const [selectedFuelType, setSelectedFuelType] = useState(fuelType);
  const [selectedStation, setSelectedStation] = useState();
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const analytics = useAnalytics();
  const showOffers = useFeatureGatedSlashPricing();
  const { isPayUser } = useProfile();
  const [userShowPayPrices, setUserShowPayPrices] = useParsedLocalStorage('lastShowPayPrices', true);

  const region = getRegionByName(regionUrlName);
  const { mobile: isMobileDisplay } = useWindowSize();
  const regionCode = region?.abbr;
  const regionCountryCode = region?.countryCode;
  const regionDisplayName = region?.name;
  const isAustralianArea = region?.countryCode === AUSTRALIA_COUNTRY_CODE;

  if (regionUrlName && !region) {
    throw new InvalidParamError(`Invalid Region: ${regionUrlName}`);
  }

  const {
    loading: isFetchingLocation,
    error: locationError,
    data: fetchedData,
  } = useQuery(GET_LOCATION, {
    skip: isAustralianArea,
    variables: {
      area,
      countryCode: regionCountryCode,
      criteria: getRegionalGasPricesAreaCriteria(area, countyId),
      fuel: selectedFuelType,
      regionCode,
    },
  });

  const isInvalidLocation = locationError && isLocationInfoError(locationError);

  if (isInvalidLocation) {
    staticContext.status = 404;
  }

  const { locationByArea: locationData } = fetchedData || {};
  const { displayName: locationDisplayName, locationType } = locationData || {};

  // See WEB-2986 as to why we might get more than 10 stations back
  const stations = locationData?.stations?.results.slice().sort(stationSort).slice(0, 10) || [];

  const showOfferToggle = useMemo(() => showOffers && isPayUser && stations.some(station => station?.offers?.length), [isPayUser, showOffers, stations]);

  let areaDisplayName = locationDisplayName || regionDisplayName;

  if (locationType === 'metro') {
    // Metro display names do not automatically come with the region code in them
    areaDisplayName = `${locationDisplayName}, ${regionCode}`;
  } else if (locationType === 'county') {
    // County display names do not automatically come with the word "County" in them
    const splitGeoArea = locationDisplayName.split(',');
    if (splitGeoArea.length === 1) { // protect against not getting the regionCode in the display name
      areaDisplayName = `${splitGeoArea} County, ${regionCode}`;
    } else {
      areaDisplayName = splitGeoArea.join(' County,');
    }
  }

  // Scroll to the top and allow the fetching of stations again when the location in the url changes
  useEffect(() => {
    scrollToTop();
  }, []);

  const logGasBuddyPayPromoClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.GASBUDDY_CARD_LEGACY_AD_CLICKED,
      attributes: { 'Page Type': pageName },
    });
  }, [analytics]);

  const logGasBuddyAppPromoClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.GASBUDDY_APP_LEGACY_AD_CLICKED,
      attributes: { 'Page Type': pageName },
    });
  }, [analytics]);

  const toggleMap = useCallback(() => {
    setShowMap(currentValue => !currentValue);
  }, []);

  const toggleShowPayPrices = useCallback(() => {
    setUserShowPayPrices(currentValue => !currentValue);
  }, [setUserShowPayPrices]);

  const handleFuelTypeChange = useCallback(({ target }) => {
    const selectedLabel = getFuelOption(target.value).label;
    setSelectedFuelType(Number(target.value));
    store.set('lastFuelType', selectedLabel);
    analytics.identifyUser({ fuel_type: selectedLabel });
  }, [analytics]);

  const handleCloseDetailBelt = useCallback(() => setSelectedStation(undefined), []);

  const handleViewMapClicked = useCallback((e) => {
    e.preventDefault();
    setShowMap(true);
    setMapIsOpen(true);
  }, []);

  const handleHideMapClicked = useCallback((e) => {
    e.preventDefault();
    setMapIsOpen(false);
  }, []);

  const handleMapClosed = useCallback(() => setMapIsOpen(false), []);

  const { counties, localities, metros } = locationData || {};
  const currentCounty = locationType === 'county' ? { id: countyId, name: areaDisplayName.split(' County')[0] } : {};
  const metroItems = createAreaListItems(metros, currentCounty);
  const countyItems = createAreaListItems(counties, currentCounty);
  const localityItems = createAreaListItems(localities, currentCounty);

  const metroItemNames = metroItems?.map(item => item.displayText) || [];
  const uniqueLocalityItems = localityItems?.filter((locality) => {
    const currentLocality = areaDisplayName.split(',');
    return !metroItemNames.includes(locality.displayText) && locality.displayText !== currentLocality[0];
  });

  const topStationsAmountHeaderString = stations.length > 0 ? ` ${stations.length}` : '';

  // Both city and county get the same ad name
  const adAreaTerm = area ? 'City' : 'State';
  let pageContextTerm = 'State';
  if (area) {
    pageContextTerm = locationType === 'county' ? 'County' : 'City';
  }
  const desktopBannerName = `GasBuddy_${adAreaTerm}_728x90`;
  const mobileBannerName = `GasBuddy_MobileWeb_320x50_${adAreaTerm}`;
  const helmetTitle = region ? `Best Gas Prices & Local Gas Stations in ${areaDisplayName}` : 'Page not found';
  const gasStationPhrase = `best${topStationsAmountHeaderString} gas ${pluralizeIfNeeded(stations.length, 'station')}`;
  const helmetDescription = `Today's ${gasStationPhrase} with the cheapest prices near you, in ${areaDisplayName}. GasBuddy provides the most ways to save money on fuel.`;
  const bannerAds = useBannerAds(desktopBannerName, mobileBannerName);

  // Build breadcrumb outline for the user and the page schema
  const { previousCountyId, previousCountyName } = history?.location?.state || {};
  const haveKnownLocation = !isFetchingLocation && !locationError;
  const breadcrumbOutline = [
    { linkTo: '/', text: 'Home' },
    { linkTo: '/gasprices', text: 'Gas Prices' },
  ];

  // We may have a known region but an unknown location within the region, therefore give link to known region
  // ex: /gasprices/saskatchewan/testtest
  if (regionDisplayName) {
    breadcrumbOutline.push({
      linkTo: `/gasprices/${canonicalizeString(regionDisplayName)}`,
      text: regionDisplayName,
    });
  }

  if (haveKnownLocation) {
    // county localities receive special url treatment due to having a countyId
    // this allows us to properly breadcrumb users arriving at a locality from a county page
    if (previousCountyId || countyId) {
      breadcrumbOutline.push({
        linkTo: canonicalizeString(`/gasprices/${regionDisplayName}/${previousCountyName || area}/${previousCountyId || countyId}`),
        text: previousCountyName ? `${previousCountyName} County` : areaDisplayName.split(',')[0],
      });
    }

    // include the breadcrumb to the locality page unless we're on a county page (which the previous block handles)
    if (area && !countyId) {
      breadcrumbOutline.push({
        linkTo: canonicalizeString(`/gasprices/${regionDisplayName}/${area}`),
        text: areaDisplayName.split(',')[0],
      });
    }
  }

  // push empty breadcrumb to properly lead users back to a valid page
  if (locationError) {
    breadcrumbOutline.push({
      text: '',
    });
  }

  const geoMarkers = stations.map(({ address, brands, latitude: lat, longitude: lng, prices, id }) => {
    const logo = brands?.find(brand => brand.brandingType === 'fuel')?.imageUrl;
    const displayPrice = prices && getDisplayPriceFromPriceReportList(prices, { fuelType: selectedFuelType });

    return (
      <GoogleMap.Marker
        key={id}
        lat={lat}
        lng={lng}
        icon={(
          <StationPin
            className={cx('stationPin')}
            country={address.country}
            displayPrice={displayPrice}
            id={id}
            isSelected={id === selectedStation}
            logo={logo}
            onClick={setSelectedStation}
          />
        )}
      />
    );
  }).reverse(); // reverse the marker order so the cheaper station markers load on top

  const breadCrumbNav = breadcrumbOutline.map(({ linkTo, text }, idx) => (
    <Breadcrumb
      key={linkTo || 'unknown'}
      active={idx === breadcrumbOutline.length - 1}
      linkTo={linkTo}
      showSeparator={idx > 0 && !!text}
    >
      {idx === 0
        ? (
          <Fragment>
            <FontAwesomeIcon icon={faHome} />
            <Text className={cx('hiddenMobile')}>
              &nbsp;
              {text}
            </Text>
          </Fragment>
        )
        : text
      }
    </Breadcrumb>
  ));

  const pageMeta = useMemo(() => (
    region ? {
      RegionCode: regionCode,
      RegionName: regionDisplayName,
      Area: areaDisplayName,
      CountyId: countyId,
    } : {}
  ), [areaDisplayName, countyId, region, regionCode, regionDisplayName]);

  const selectedStationData = useMemo(() => stations.find(s => s.id === selectedStation), [selectedStation, stations]);

  const createMapOptions = useMemo(() => ({
    fullscreenControl: false,
    streetViewControl: false,
    zoomControlOptions: {
      position: 1, // LEFT_TOP, so it isn't covered by the station info box on mobile
    },
  }), []);

  const stationSchema = generateRegionalStationsSchema(stations);
  const breadcrumbSchema = generateBreadcrumbSchema(breadcrumbOutline);

  return (
    <Fragment>
      <Page
        name={`${pageName}_${pageContextTerm}`} // updated in v9
        meta={pageMeta}
        activeNavKey="find_gas"
        bannerAds={bannerAds}
        breadcrumbSchema={breadcrumbSchema}
        description={helmetDescription}
        schema={stationSchema}
        showAppBanner
        title={helmetTitle}
        withTopAd
      >
        {isAustralianArea
          ? <NoAustralia />
          : (
            <Container>
              <Visibility mobile={false}>
                {breadCrumbNav}
              </Visibility>
              <Header size="xs">
                {`Top${topStationsAmountHeaderString} Gas ${pluralizeIfNeeded(stations.length, 'Station')} & Cheap Fuel Prices${haveKnownLocation ? ` in ${areaDisplayName}` : ''}`}
              </Header>
              <FlexGrid container>
                <FlexGrid.Column>
                  <FlexGrid className={cx('filtersContainer')}>
                    <FlexGrid.Column className={cx('column')} mobile={12} tablet={4} desktop={3}>
                      <Dropdown
                        id="fuelType"
                        label="Select fuel type"
                        bordered
                        className={cx('fuelTypeFilter')}
                        defaultValue={selectedFuelType}
                        onChange={handleFuelTypeChange}
                        options={JSX_FUEL_OPTIONS}
                      />
                    </FlexGrid.Column>
                    {showOfferToggle && (
                      <FlexGrid.Column className={cx('column')} mobile={12} tablet={5} desktop={4}>
                        <Panel
                          bordered
                          className={cx('payPriceContainer')}
                          color="white"
                          inline
                          padded={false}
                        >
                          <Checkbox
                            className={cx('fluidCheckbox')}
                            defaultChecked={userShowPayPrices}
                            label="Show Our Discounted Prices"
                            onClick={toggleShowPayPrices}
                          />
                        </Panel>
                      </FlexGrid.Column>
                    )}
                    {stations.length > 0 && (
                      <FlexGrid.Column className={cx('column', 'nonMobile')} mobile={12} tablet={3}>
                        <Panel
                          bordered
                          className={cx('payPriceContainer')}
                          color="white"
                          inline
                          padded={false}
                        >
                          <Checkbox
                            checked={showMap}
                            className={cx('fluidCheckbox')}
                            label="Show Map"
                            onChange={toggleMap}
                          />
                        </Panel>
                      </FlexGrid.Column>
                    )}
                  </FlexGrid>
                </FlexGrid.Column>
                <FlexGrid.Column className={cx('column')} desktop={10}>
                  <FlexGrid container>
                    <FlexGrid.Column className={cx('column')} desktop={7}>
                      <StationsMapContext.Provider value={selectedStation}>
                        {isFetchingLocation
                          ? <Loader style={loaderStyles} />
                          : !locationError && (
                            stations.length === 0
                              ? <Text as="p" className={cx('noStations')} color="midnight" italic>No stations found.</Text>
                              : (
                                <GenericStationList
                                  fuelType={selectedFuelType}
                                  isFullTabletWidth
                                  showPayBanner={false}
                                  stations={stations}
                                  verbose
                                />
                              )
                          )
                        }
                      </StationsMapContext.Provider>
                      {!!locationError && (
                        <Fragment>
                          {isInvalidLocation && (
                            <Helmet>
                              <title>Page not found</title>
                              <meta name="robots" content="noindex" />
                            </Helmet>
                          )}
                          <br />
                          <Text as="p" size="xl" color="orange">
                            {isInvalidLocation ? 'Page not found.' : 'An error occurred retrieving stations for this area.' }
                          </Text>
                        </Fragment>
                      )}
                    </FlexGrid.Column>
                    <FlexGrid.Column className={cx('column', 'secondary')} mobile={12}>
                      <div id="gb-promo-pay-and-app">
                        <Visibility mobile={false}>
                          <Link to="/pay" onClick={logGasBuddyPayPromoClick}>
                            <Image
                              src="https://images.gasbuddy.com/images/websites/gasbuddy/cta/cta-cashback-medium.jpg"
                              alt="Click here to earn instant savings of up to $10 every tank!"
                            />
                          </Link>
                        </Visibility>
                        <Visibility tablet={false} desktop={false}>
                          <a
                            href="https://routing.gasbuddy.com/app"
                            onClick={logGasBuddyAppPromoClick}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <Image
                              src="https://images.gasbuddy.com/images/websites/gasbuddy/cta/cta-download-app-mobile.png"
                              alt="Click here to download the GasBuddy App and start saving today!"
                            />
                          </a>
                        </Visibility>
                      </div>
                    </FlexGrid.Column>
                    <FlexGrid.Column className={cx('column', { secondary: showMap })} desktop={showMap ? 7 : 5}>
                      {!!metroItems?.length && (
                        <AreaCountyList title="Major Areas" items={metroItems} />
                      )}
                      {!!countyItems?.length && (
                        <AreaCountyList title="State Counties" items={countyItems} />
                      )}
                      {!!uniqueLocalityItems?.length && (
                        <AreaCountyList title={`Local Prices in ${areaDisplayName}`} items={uniqueLocalityItems} />
                      )}
                    </FlexGrid.Column>
                    <FlexGrid.Column className={cx('column', 'secondary')} desktop={showMap ? 5 : 12}>
                      {!!region && (
                        <RegionContainer country={regionCountryCode} />
                      )}
                    </FlexGrid.Column>
                    {!mapIsOpen && (
                      <Button secondary className={cx('viewMapButton')} onClick={handleViewMapClicked}>
                        <FontAwesomeIcon icon={faMap} />&nbsp;
                        View Map
                      </Button>
                    )}
                    {showMap && (
                      <FlexGrid.Column className={cx('column')} desktop={5}>
                        <Tray
                          className={cx('mapContainer', { closedMap: !mapIsOpen })}
                          isOpen={mapIsOpen}
                          label={(
                            <Button tertiary className={cx('hideMapLabel')} onClick={handleHideMapClicked}>
                              Hide Map&nbsp;
                              <FontAwesomeIcon icon={faMap} />
                            </Button>
                          )}
                          onClose={handleMapClosed}
                        >
                          <div className={cx('map')}>
                            {!isFetchingLocation && (
                              <GoogleMap
                                accessToken={mapKey}
                                defaultZoom={8}
                                fitBounds
                                options={createMapOptions}
                              >
                                {geoMarkers}
                              </GoogleMap>
                            )}
                            {isMobileDisplay && typeof selectedStation !== 'undefined' && (
                              <StationDetailBelt
                                className={cx('stationItemMobileContainer')}
                                fuelType={selectedFuelType}
                                onClose={handleCloseDetailBelt}
                                station={selectedStationData}
                              />
                            )}
                          </div>
                        </Tray>
                      </FlexGrid.Column>
                    )}
                    <FlexGrid.Column className={cx('column', 'secondary')} desktop={5}>
                      <Visibility tablet={false} desktop={false}>
                        <Header as="h2" className={cx('breadcrumbHeader')} color="grey">You Are Here</Header>
                        {breadCrumbNav}
                      </Visibility>
                    </FlexGrid.Column>
                  </FlexGrid>
                </FlexGrid.Column>
                <FlexGrid.Column className={cx('column')} desktop={2}>
                  <RegionAds
                    topAdName={`GasBuddy_${adAreaTerm}_160x600_1`}
                    middleAdName={`GasBuddy_${adAreaTerm}_160x600_2`}
                    bottomAdName={`GasBuddy_${adAreaTerm}_160x600_3`}
                  />
                </FlexGrid.Column>
              </FlexGrid>
            </Container>
          )
        }
      </Page>
    </Fragment>
  );
}

RegionalGasPrices.propTypes = {
  staticContext: StaticContextPropType,
};

RegionalGasPrices.defaultProps = {
  staticContext: {},
};
