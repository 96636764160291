import { Button, Image, Loader } from '@gasbuddy/react-components';
import equal from 'fast-deep-equal';
import PropTypes from 'prop-types';
import { Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useBadgeAdImpressions from '../../../lib/hooks/useBadgeAdImpressions';
import useGeoIpInfo from '../../../lib/hooks/useGeoIpInfo';
import usePageContext from '../../../lib/hooks/usePageContext';
import useProfile from '../../../lib/hooks/useProfile';
import { noop } from '../../../lib/utils';
import buildStationWithDisplayPrice from '../../../lib/utils/buildStationWithDisplayPrice';
import { ANALYTICS_EVENTS, AUSTRALIA_COUNTRY_CODE, COUNTRY_CODES } from '../../constants';
import FuelTypePropType from '../../prop-types/fuelType';
import StationPropTypes from '../../prop-types/station';
import GenericStationListItem from '../GenericStationListItem';

export function GenericStationList({
  fuelType,
  hidePayBanner,
  includeCash,
  includeLoadMoreButton,
  isFetchingStations,
  isFullTabletWidth,
  loadMoreButtonHref,
  loadMoreStations,
  locationCountryCode,
  maxAge,
  search,
  showBadges,
  stations,
  verbose,
}) {
  const { isPayUser } = useProfile();
  const isUSUser = useGeoIpInfo().country === COUNTRY_CODES.USA;
  const showPayBanner = !isPayUser && isUSUser && !hidePayBanner;
  const analytics = useAnalytics();
  const { pageName } = usePageContext();

  const stationsWithDisplayPrice = useMemo(() => buildStationWithDisplayPrice({
    stations,
    maxAge,
    includeCash,
    fuelType,
  }), [stations, maxAge, includeCash, fuelType]);

  const numberStationsBeforeBannerAd = 1;
  const stationsBeforeBannerAd = stationsWithDisplayPrice.slice(0, numberStationsBeforeBannerAd);
  const stationsAfterBannerAd = stationsWithDisplayPrice.slice(numberStationsBeforeBannerAd);
  const needsToTryLoadingMoreStations = !!maxAge && !stationsWithDisplayPrice.length && includeLoadMoreButton;
  const shouldLoadMoreStations = needsToTryLoadingMoreStations && !isFetchingStations;
  const shouldShowLoading = isFetchingStations && !stationsWithDisplayPrice.length;
  const isFetchingMoreStations = stations.length && isFetchingStations;

  const handleLoadMoreStations = useCallback((e) => {
    e.preventDefault();
    loadMoreStations();
  }, [loadMoreStations]);

  const logGasBuddyCardBannerClickEvent = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.GASBUDDY_CARD_BANNER_CLICKED,
      attributes: {
        Location: 'StationList',
        'Page Type': pageName,
      },
    });
  }, [analytics, pageName]);

  const badgeImpressions = stations.reduce((allImpressions, station) => {
    const newImpressions = (station?.badges || [])
      .map(badge => ({
        Date_Viewed: (new Date()).toISOString(),
        Station_Id: station.id.toString(),
        List_Ad_Id: badge.campaignId.toString(),
      }));

    return [...allImpressions, ...newImpressions];
  }, []);

  // Record badge impressions
  useBadgeAdImpressions(badgeImpressions);

  useEffect(() => {
    if (shouldLoadMoreStations) {
      loadMoreStations();
    }
  }, [shouldLoadMoreStations, loadMoreStations]);

  let resultsContent;
  if (shouldShowLoading) {
    resultsContent = (
      <Loader style={{ margin: '50px auto' }} />
    );
  } else if (stationsWithDisplayPrice.length) {
    resultsContent = (
      <Fragment>
        {stationsBeforeBannerAd.map(station => (
          <GenericStationListItem
            key={station.id}
            fuelType={fuelType}
            includeCash={includeCash}
            isFullTabletWidth={isFullTabletWidth}
            showBadges={showBadges}
            station={station}
            verbose={verbose}
          />
        ))}
        {showPayBanner && (
          <a
            href="/pay"
            onClick={logGasBuddyCardBannerClickEvent}
          >
            <Image
              alt="Pay With GasBuddy Banner"
              fluid
              src="https://static.gasbuddy.com/web/pay/pay-ad-700x140.png"
              style={{ margin: '1rem 0' }}
            />
          </a>
        )}
        {stationsAfterBannerAd.map(station => (
          <GenericStationListItem
            key={station.id}
            fuelType={fuelType}
            includeCash={includeCash}
            isFullTabletWidth={isFullTabletWidth}
            showBadges={showBadges}
            station={station}
            verbose={verbose}
          />
        ))}
      </Fragment>
    );
  } else if (search?.length) {
    if (locationCountryCode === AUSTRALIA_COUNTRY_CODE) {
      resultsContent = (
        <em>No stations found. Please note that GasBuddy is no longer operating in Australia.</em>
      );
    } else {
      resultsContent = (
        <em>No stations found. Try refining your search.</em>
      );
    }
  }

  return (
    <div>
      {resultsContent}
      {includeLoadMoreButton && !needsToTryLoadingMoreStations && (
        <Fragment>
          <br />
          <Button
            as="a"
            href={loadMoreButtonHref}
            onClick={handleLoadMoreStations}
            loading={isFetchingMoreStations}
            secondary
            fluid
          >
            {`More ${search || 'Nearby'} Gas Prices`}
          </Button>
        </Fragment>
      )}
    </div>
  );
}

export default memo(GenericStationList, equal);

GenericStationList.propTypes = {
  fuelType: FuelTypePropType,
  hidePayBanner: PropTypes.bool,
  includeCash: PropTypes.bool,
  includeLoadMoreButton: PropTypes.bool,
  isFetchingStations: PropTypes.bool,
  isFullTabletWidth: PropTypes.bool,
  loadMoreButtonHref: PropTypes.string,
  loadMoreStations: PropTypes.func,
  locationCountryCode: PropTypes.string,
  maxAge: PropTypes.number,
  search: PropTypes.string,
  showBadges: PropTypes.bool,
  stations: PropTypes.arrayOf(StationPropTypes),
  verbose: PropTypes.bool,
};

GenericStationList.defaultProps = {
  fuelType: 1,
  hidePayBanner: false,
  includeCash: true,
  includeLoadMoreButton: false,
  isFetchingStations: false,
  isFullTabletWidth: false,
  loadMoreButtonHref: undefined,
  loadMoreStations: noop,
  locationCountryCode: COUNTRY_CODES.USA,
  maxAge: undefined,
  search: undefined,
  showBadges: true,
  stations: [],
  verbose: false,
};
