import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Belt, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import uniqid from 'uniqid';
import useFeatureGatedSlashPricing from '../../../lib/hooks/useFeaturedGatedSlashPricing';
import useFormattedStationPrices from '../../../lib/hooks/useFormattedStationPrices';
import useStationPayAvailability from '../../../lib/hooks/useStationPayAvailability';
import FuelTypePropType from '../../prop-types/fuelType';
import StationPropType from '../../prop-types/station';
import StationDisplay from '../StationDisplay';
import StationDisplayPrice from '../StationDisplayPrice';
import StationOfferPrice from '../StationOfferPrice';
import styles from './StationDetailBelt.module.css';

const cx = classnames.bind(styles);

export default function StationDetailBelt({
  className,
  fuelType,
  onClose,
  station,
  ...rest
}) {
  const [beltIsOpen, setBeltIsOpen] = useState(false);

  const handleBeltOpen = useCallback(() => {
    setBeltIsOpen(true);
  }, []);

  const handleBeltClose = useCallback(() => {
    setBeltIsOpen(false);
  }, []);

  useEffect(() => {
    setBeltIsOpen(false);
  }, [station]);

  const { address, id: stationId, offers, prices } = station || {};

  const {
    discountedPrice,
    cashPriceReport,
    creditPriceReport,
    hasActiveCashPrice,
  } = useFormattedStationPrices(address?.country, fuelType, offers, prices);
  const priceReport = hasActiveCashPrice ? cashPriceReport : creditPriceReport;

  const isPayAvailable = useStationPayAvailability(station);
  const showOffers = useFeatureGatedSlashPricing();
  const shouldShowOfferPrice = showOffers && isPayAvailable && discountedPrice;

  const components = [
    <StationDisplayPrice
      formattedPrice={priceReport?.formattedPrice}
      isCash={hasActiveCashPrice}
      postedTime={priceReport?.postedTime}
      reportedBy={priceReport?.reportedBy}
    />,
  ];

  if (shouldShowOfferPrice) {
    components.unshift(
      <StationOfferPrice
        payPrice={discountedPrice}
        regularPriceReport={creditPriceReport}
        showPriceTab={hasActiveCashPrice}
        smallMobilePrice
      />,
    );
  }

  return (
    <Panel padded={false} className={cx('container', { fadedInfo: beltIsOpen }, className)}>
      <StationDisplay
        station={station}
        {...rest}
      />
      <Belt
        activeOnTablet
        className={cx('beltContainer')}
        name={`${stationId}_PriceBelt`}
        onOpen={handleBeltOpen}
        onClose={handleBeltClose}
      >
        {components.map(comp => (
          <Panel key={uniqid()} raised padded={false} color="white" className={cx('beltElementContainers')}>
            {comp}
          </Panel>
        ))}
      </Belt>
      <button
        aria-label="Close Station Box"
        className={cx('closeIcon')}
        onClick={onClose}
        type="button"
      >
        <FontAwesomeIcon icon={faTimes} size="sm" />
      </button>
    </Panel>
  );
}

StationDetailBelt.propTypes = {
  className: PropTypes.string,
  fuelType: FuelTypePropType,
  onClose: PropTypes.func,
  showOffer: PropTypes.bool,
  station: StationPropType,
};

StationDetailBelt.defaultProps = {
  className: undefined,
  fuelType: 1,
  onClose: () => { },
  showOffer: false,
  station: undefined,
};
