import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AdSizesPropType, AdSizeMappingPropType, AdParams } from '../../prop-types/ad';
import { DFP_NETWORK_ID } from '../../constants/ads';

export const AdStatus = {
  Initial: 0, // Nothing has been done
  Initializing: 1, // SDK Loaded
  Ready: 2, // Slots registered
  Loading: 3, // Fetching ads
  Loaded: 4, // Done
};

export default function AdSlot({ adUnit, id, sizes, sizeMapping, params, collapseIfEmpty }) {
  const slotID = useMemo(() => id || AdSlot.generateID(), [id]);
  const [status, setStatus] = useState(AdStatus.Initial);
  const slotEl = useRef();
  const slot = useRef();

  // Fetch new ad
  const refreshAd = useCallback(() => {
    setStatus(AdStatus.Loading);

    // request bids for the slots you are planning to render
    window.apstag.fetchBids({
      slots: [
        {
          slotID,
          slotName: `${DFP_NETWORK_ID}/${adUnit}`,
          sizes,
        },
      ],
    }, (bids) => {
      window.googletag.cmd.push(() => {
        // We extend the bid with an empty meta array to avoid the following issue in local env
        // TypeError: undefined is not an object (evaluating 'this.bidConfig.meta.filter')
        window.apstag.setDisplayBids(bids.map(bid => ({ meta: [], ...bid })));
        window.googletag.pubads().refresh([slot.current]);
        setStatus(AdStatus.Loaded);
      });
    });
  }, [adUnit, sizes, slotID]);

  // Initialize the Ad
  useEffect(() => {
    // Update component state to indicate loading status
    setStatus(AdStatus.Initializing);

    window.googletag.cmd.push(() => {
      const collapseBeforeFetch = true;

      // Constructs an ad slot with a given ad unit path and size and associates it with the ID of
      // a div element on the page that will contain the ad.
      slot.current = window.googletag.defineSlot(
        `/${DFP_NETWORK_ID}/${adUnit}`,
        sizes.length > 1 ? sizes : sizes[0],
        slotID,
      );

      if (collapseIfEmpty) {
        slot.current.setCollapseEmptyDiv(collapseIfEmpty, collapseBeforeFetch);
      }

      if (sizeMapping && sizeMapping.length) {
        slot.current.defineSizeMapping(sizeMapping);
      }

      if (params) {
        Object.entries(params).forEach(([k, v]) => {
          if (v) {
            slot.current.setTargeting(k, v);
          }
        });
      }

      slot.current.addService(window.googletag.pubads());

      // enables Single Request Architecture (SRA)
      window.googletag.pubads().enableSingleRequest();

      // Disable initial load, we will use refresh() to fetch ads. Calling this function means that
      // display() calls just register the slot as ready, but do not fetch ads for it.
      window.googletag.pubads().disableInitialLoad();

      // Enables all GPT services that have been defined for ad slots on the page.
      window.googletag.enableServices();

      // register the slot as ready
      window.googletag.display(slotID);

      setStatus(AdStatus.Ready);
    });

    return () => {
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots([slot.current]);
      });
    };
  }, [adUnit, collapseIfEmpty, params, sizeMapping, sizes, slotID]);

  // Load the first ad if ready
  useEffect(() => {
    if (status === AdStatus.Ready) {
      refreshAd();
    }
  }, [refreshAd, status]);

  return (
    <div id={slotID} ref={slotEl} />
  );
}

AdSlot.propTypes = {
  id: PropTypes.string,
  adUnit: PropTypes.string.isRequired,
  sizes: AdSizesPropType.isRequired,
  sizeMapping: AdSizeMappingPropType,
  params: AdParams,
  collapseIfEmpty: PropTypes.bool,
};

AdSlot.defaultProps = {
  id: undefined,
  sizeMapping: undefined,
  params: undefined,
  collapseIfEmpty: false,
};

AdSlot.adCount = 0;

AdSlot.generateID = () => {
  AdSlot.adCount += 1;
  return `adSlot-${AdSlot.adCount}`;
};
