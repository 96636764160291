import PropTypes from 'prop-types';

export const Brand = PropTypes.shape({
  brandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  brandingType: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  logoVersion: PropTypes.number,
  name: PropTypes.string,
  uri: PropTypes.string,
});

export const BrandMapPropType = PropTypes.objectOf(
  PropTypes.shape({
    [PropTypes.number]: Brand,
  }),
);

export default Brand;
