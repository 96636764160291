import PropTypes from 'prop-types';
import { Image } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import { DEFAULT_STATION_LOGO } from '../../constants';
import getOptimizedImageUrl from '../../../lib/utils/getOptimizedImageUrl';
import styles from './StationBrandings.module.css';
import { uniqueReducer } from '../../../lib/utils';

const cx = classnames.bind(styles);

function StationBranding({ logo, name }) {
  return (
    <div className={cx('logoImageContainer')}>
      <Image
        src={getOptimizedImageUrl(logo || DEFAULT_STATION_LOGO, 33)}
        alt={name}
      />
    </div>
  );
}

StationBranding.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
};

StationBranding.defaultProps = {
  logo: undefined,
  name: undefined,
};

function StationBrandings({
  brands,
  className,
  stationName,
  ...rest
}) {
  // Filter out duplicate brands and order by branding type
  const organizedBrands = brands
    .map(brand => brand.brandId)
    .reduce(uniqueReducer, [])
    .map(brandId => brands.find(brand => brand.brandId === brandId))
    .sort(a => (a.brandingType === 'fuel' ? -1 : 1));
  const [fuelBrand, cstoreBrand] = organizedBrands;

  return (
    <div className={cx('logoImages', className)} {...rest}>
      <StationBranding
        logo={fuelBrand?.imageUrl}
        name={fuelBrand?.name || stationName}
      />
      {cstoreBrand && (
        <StationBranding
          logo={cstoreBrand.imageUrl}
          name={cstoreBrand.name || stationName}
        />
      )}
    </div>
  );
}

StationBrandings.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  stationName: PropTypes.string.isRequired,
};

StationBrandings.defaultProps = {
  brands: [],
  className: undefined,
};

export default StationBrandings;
