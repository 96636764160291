import { Actions, Header, Image, Link, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { noop } from '../../../lib/utils';
import styles from './ThankYou.module.css';

const cx = classnames.bind(styles);

function ThankYouContent({ messageType, pointsAwarded }) {
  if (messageType === 'review') {
    return (
      <Fragment>
        <Header as="h3">Thanks for helping out!</Header>
        <Text as="p">
          Your ratings will help improve this location and help the millions of GasBuddy users.
        </Text>
        <br />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header as="h3">Prices submitted, thank you!</Header>
      <div className={cx('successImageContainer')}>
        <Image
          alt="Submission Successful Trophy Icon"
          src="https://static.gasbuddy.com/web/consumer/submission-trophy.svg"
        />
      </div>
      {pointsAwarded > 0 && (
        <Text as="p">
          You have earned <Text as="strong">{pointsAwarded} points</Text> for those prices.
        </Text>
      )}
      <Text as="p">
        Every 1000 points for reporting prices earns you an entry into our daily prize draw to win $100 in FREE gas.
      </Text>
    </Fragment>
  );
}

ThankYouContent.propTypes = {
  messageType: PropTypes.string.isRequired,
  pointsAwarded: PropTypes.number,
};

ThankYouContent.defaultProps = {
  pointsAwarded: 0,
};

export default function ThankYou({
  messageType,
  onDone,
  pointsAwarded,
}) {
  return (
    <Fragment>
      <ThankYouContent messageType={messageType} pointsAwarded={pointsAwarded} />
      <br />
      <Actions>
        <Actions.Button
          primary
          wide
          onClick={onDone}
          desktop={6}
          data-testid="doneButton"
        >
          Done
        </Actions.Button>
        {messageType === 'price' && (
          <Actions.Action desktop={6}>
            <Link to="/contest/gas-card-give-away" uppercase>
              Enter To Win
            </Link>
          </Actions.Action>
        )}
      </Actions>
    </Fragment>
  );
}

ThankYou.propTypes = {
  messageType: PropTypes.oneOf(['review', 'price']).isRequired,
  onDone: PropTypes.func,
  pointsAwarded: PropTypes.number,
};

ThankYou.defaultProps = {
  onDone: noop,
  pointsAwarded: 0,
};
