import { capitalize } from '.';
import { FUEL_POI_VALUE_TO_NAME } from '../../common/constants';

function getFuelBrand(brands) {
  return brands.length ? brands.find(b => b.brandingType === 'fuel') : undefined;
}

function getCstoreBrand(brands) {
  return brands.length ? brands.find(b => b.brandingType === 'cstore') : undefined;
}

function getStationBrand(brands) {
  return getFuelBrand(brands) || getCstoreBrand(brands);
}

const getFuelBrandName = brands => ((getFuelBrand(brands) || {}).name || '').trim();

const getCstoreBrandName = brands => ((getCstoreBrand(brands) || {}).name || '').trim();

function getStationDisplayName(brands, stationName) {
  if (brands?.length === 2) {
    const [firstBrand, secondBrand] = brands;
    if (firstBrand.brandId === secondBrand.brandId) {
      return firstBrand.name;
    }

    const fuelBrandName = getFuelBrandName(brands);
    const cstoreBrandName = getCstoreBrandName(brands);

    if (!fuelBrandName || !cstoreBrandName) {
      return stationName;
    }
    return `${fuelBrandName} & ${cstoreBrandName}`;
  }

  return stationName;
}

/**
 *
 * @param {string} poiFuelType The poi fuel type to filter prices by
 * @param {boolean} ascending Whether to sort in ascending order or not
 * @returns A sorted list of stations based on the fuel type price
 */
const fuelTypePriceSorter = (poiFuelType, ascending = true) => (a, b) => {
  const aPrice = a.prices.find(p => p.fuelProduct === poiFuelType);
  const bPrice = b.prices.find(p => p.fuelProduct === poiFuelType);
  if (!aPrice || !bPrice) {
    return 0;
  }

  // Always chuck stations with no prices to the bottom
  const aPriceValue = aPrice.cash && aPrice.cash.price > 0 ? aPrice.cash.price : aPrice.credit?.price;
  if (!aPriceValue || aPriceValue === 0) {
    return 1;
  }

  const bPriceValue = bPrice.cash && bPrice.cash.price > 0 ? bPrice.cash.price : bPrice.credit?.price;
  if (!bPriceValue || bPriceValue === 0) {
    return -1;
  }
  return ascending ? aPriceValue - bPriceValue : bPriceValue - aPriceValue;
};

const getMSLStationFromPoiStation = (poiStation) => {
  const fuelBrand = (poiStation.brands || []).find(brand => brand.brandingType === 'fuel');
  return {
    ...poiStation,
    openStatus: capitalize(poiStation.openStatus.replace(/_/g, ' '), true),
    brand: {
      brandId: fuelBrand?.brandId || 0,
      name: fuelBrand?.name || 'Unbranded',
    },
    fuels: poiStation.fuels.map(fuel => FUEL_POI_VALUE_TO_NAME[fuel]),
    amenities: poiStation.amenities.map(amenity => amenity.name),
  };
};

export {
  fuelTypePriceSorter,
  getFuelBrand,
  getFuelBrandName,
  getCstoreBrand,
  getCstoreBrandName,
  getMSLStationFromPoiStation,
  getStationBrand,
  getStationDisplayName,
};
