import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';
import FeaturesContext from '../../context/features';
import styles from './AlertBanner.module.css';

const cx = classnames.bind(styles);

const universalCookies = new Cookies();

export default function AlertBanner({ featureName, isPartnerPage }) {
  const analytics = useAnalytics();
  const features = useContext(FeaturesContext);
  const feature = features?.find(f => f.n === featureName);
  const variantName = feature?.v;
  const content = feature?.p?.html;
  const version = feature?.p?.version;
  const showOnPartnerPage = feature?.p?.showOnPartnerPage;
  const bannerId = `${featureName}_${variantName}`;

  const wasClosed = useMemo(() => {
    const lastClosedVersion = universalCookies.get(bannerId);
    return !!lastClosedVersion && lastClosedVersion.toString() === version.toString();
  }, [bannerId, version]);

  const [isHidden, setIsHidden] = useState(wasClosed);

  const handleBannerClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.ALERT_BANNER_CLICK,
      attributes: { bannerId },
    });
  }, [analytics, bannerId]);

  const handleCloseClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.ALERT_BANNER_CLOSE,
      attributes: { bannerId },
    });

    // Store current version in cookie, never expire, can only be busted with new version
    universalCookies.set(bannerId, version);

    setIsHidden(true);
  }, [analytics, bannerId, version]);

  if (isHidden || !content || (isPartnerPage && !showOnPartnerPage)) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: content,
        }}
        onClick={handleBannerClick}
      />
      <FontAwesomeIcon
        icon={faTimes}
        className={cx('close')}
        onClick={handleCloseClick}
      />
    </div>
  );
}

AlertBanner.propTypes = {
  featureName: PropTypes.string.isRequired,
  isPartnerPage: PropTypes.bool,
};

AlertBanner.defaultProps = {
  isPartnerPage: false,
};
