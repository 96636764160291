import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './ZoomSlider.module.css';

const cx = classnames.bind(styles);

function ZoomSlider({ className, onChange }) {
  return (
    <div className={cx('modalSliderContainer', className)}>
      <img
        className={cx('iconSmaller')}
        src="//static.gasbuddy.com/web/consumer/profile/pic-icon.svg"
        alt="Smaller"
      />
      <input
        className={cx('gbRange')}
        name="scale"
        type="range"
        onChange={onChange}
        min={0.1}
        max={2}
        step={0.01}
        defaultValue={1}
      />
      <img
        className={cx('iconLarger')}
        src="//static.gasbuddy.com/web/consumer/profile/pic-icon.svg"
        alt="Larger"
      />
    </div>
  );
}

ZoomSlider.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

ZoomSlider.defaultProps = {
  className: undefined,
  onChange: undefined,
};

export default ZoomSlider;
