import { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@gasbuddy/react-components';
import { noop } from '../../../lib/utils';
import { FavoriteListPropType } from '../../prop-types/favoriteList';
import sortFavoriteLists from '../../../lib/utils/sortFavoriteLists';

export default function FavoriteListsDropdown({
  favoriteLists,
  onChange,
  ...rest
}) {
  const [listsWithDefaultFirst, setListsWithDefaultFirst] = useState(favoriteLists);

  const handleChange = useCallback(({ target }) => {
    const favoriteList = favoriteLists.find(list => list.id === target.value);
    onChange(favoriteList);
  }, [onChange, favoriteLists]);

  // once favorite lists are available, sort them and notify consumer that the lists are ready now
  useEffect(() => {
    const sortedLists = sortFavoriteLists(favoriteLists);
    setListsWithDefaultFirst(sortedLists);
  }, [favoriteLists]);

  return (
    <Fragment>
      <Dropdown
        id="favoritesList"
        label="Select favorites list"
        onChange={handleChange}
        options={listsWithDefaultFirst.map(list => ({
          label: list.displayName,
          value: list.id,
        })) || []}
        {...rest}
      />
    </Fragment>
  );
}

FavoriteListsDropdown.propTypes = {
  favoriteLists: PropTypes.arrayOf(FavoriteListPropType).isRequired,
  onChange: PropTypes.func,
};

FavoriteListsDropdown.defaultProps = {
  onChange: noop,
};
