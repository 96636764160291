/**
 * Generates a locale time string based on a passed in Date object
 * @param {Date} date The Date object to extract the time from
 * @returns A time string in the format of HH:MM(A/P)M, where HH is from 00-12. Ex: 06:05PM
 */
export const formatLocaleTime = date => (new Date(date)).toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' }).replace(' ', '');

/**
 * Generates a month-date string based on a passed in Date object
 * @param {Date} date The Date object to extract the date from
 * @returns A date string in the format of MM/DD, with no leading 0s
 */
export const formatDate = (date) => {
  const wrappedDate = new Date(date);
  return `${wrappedDate.getMonth() + 1}/${wrappedDate.getDate()}`;
};

/**
 * Returns a string representing the long text equivalent of a Date object's Day property
 * @param {Date} date The Date object to extract the day from
 * @returns A string containing the long text equivalent of the Day property
 */
export const formatDay = date => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][(new Date(date)).getDay()];

/**
 * Generates a 24Hr timestamp string based on the a passed in Date object
 * @param {Date} date The Date object to extract the timestamp from
 * @returns A timestamp string in the format of HH:MM mm/dd
 */
export const formatTimestamp = date => `${formatLocaleTime(date)} ${formatDate(date)}`;
