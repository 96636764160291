import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './StarRating.module.css';

const cx = classnames.bind(styles);

const sizeMap = {
  small: 'sm',
  large: '1x',
};

export default function StarRating({
  emptyStarColor,
  filledStarColor,
  name,
  rating,
  size,
  starCount,
}) {
  // Create an array with values 1 to our starCount so we can easily choose the correct color and icon for each star
  const stars = Array.from(Array(starCount).keys(), x => x + 1);

  return (
    <div className={cx('stars')}>
      {stars.map((starValue) => {
        const starColor = Math.ceil(rating) >= starValue ? filledStarColor : emptyStarColor;
        return (
          <Text
            key={`${name}_${starValue}`}
            style={{
              color: starColor,
            }}
          >
            {
              Math.ceil(rating) === starValue && rating % 1 !== 0
                ? <FontAwesomeIcon icon={faStarHalfAlt} size={sizeMap[size]} title="Half-filled Star" />
                : (
                  <FontAwesomeIcon
                    icon={starValue <= rating ? filledStar : emptyStar}
                    size={sizeMap[size]}
                    title={`${starValue <= rating ? 'Full' : 'Empty'} Star`}
                  />
                )
            }
          </Text>
        );
      })}
    </div>
  );
}

StarRating.propTypes = {
  emptyStarColor: PropTypes.string,
  filledStarColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  rating: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
  starCount: PropTypes.number,
};

StarRating.defaultProps = {
  emptyStarColor: '#d1d3d5',
  filledStarColor: '#f6cc1c',
  rating: 0,
  size: 'large',
  starCount: 5,
};
