import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexGrid, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uniqid from 'uniqid';
import { noop } from '../../../lib/utils';
import { formatLocaleTime, formatDay } from '../../../lib/utils/formatDateTime';
import StationHoursPropType from '../../prop-types/hours';
import styles from './StationHours.module.css';

const cx = classnames.bind(styles);

export default function StationHours({ hours, onChange }) {
  // See https://github.com/opening-hours/opening_hours.js for datetime formats
  const is24Hours = hours.status === '24/7';
  const nextInterval = hours && hours.nextIntervals?.[0];
  const { open: nextOpen, close: nextClose } = nextInterval || {};
  const stationHoursEl = useRef();
  const [isHoursOpen, setIsHoursOpen] = useState(false);

  const isOpen = useMemo(() => {
    if (!nextOpen || !nextClose) {
      return is24Hours;
    }
    const currently = new Date();
    const now = currently.getTime();
    const open = new Date(nextOpen).getTime();
    const close = new Date(nextClose).getTime();

    return !!(open <= now && now < close);
  }, [is24Hours, nextClose, nextOpen]);

  const handleToggleClick = useCallback(() => {
    setIsHoursOpen(!isHoursOpen);
    onChange();
  }, [isHoursOpen, onChange]);

  useEffect(() => {
    function handleClickOutside(event) {
      const el = stationHoursEl.current;

      if (el && !el.contains(event.target) && !!isHoursOpen) {
        onChange(!isHoursOpen);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHoursOpen, onChange, stationHoursEl]);

  if (is24Hours) {
    return (
      <Text bold uppercase color="blue">Open 24 Hours</Text>
    );
  }

  if (!nextInterval) {
    return (<span />);
  }

  // Today's hours or tomorrow's opening time
  const nextIntervalText = isOpen
    ? `${formatLocaleTime(nextInterval.open)}-${formatLocaleTime(nextInterval.close)}`
    : `Open at ${formatLocaleTime(nextInterval.open)}`;

  const icon = isHoursOpen ? faChevronUp : faChevronDown;

  // Popup content with hours for the upcoming days
  const HoursNode = () => (
    <FlexGrid className={cx('hoursPopUp')} data-testid="hoursPopup">
      {hours.nextIntervals.map(interval => ([
        (
          <FlexGrid.Column key={uniqid()} mobile={5}>{formatDay(interval.open)}</FlexGrid.Column>
        ),
        (
          <FlexGrid.Column key={uniqid()} mobile={7}>
            {`${formatLocaleTime(interval.open)} - ${formatLocaleTime(interval.close)}`}
          </FlexGrid.Column>
        ),
      ]))}
    </FlexGrid>
  );

  return (
    <div ref={stationHoursEl}>
      <Text bold uppercase color={isOpen ? 'blue' : 'red'}>
        {`${isOpen ? 'Open' : 'Closed'} Now: `}
      </Text>
      <Text data-testid="nextIntervalText">
        {nextIntervalText}
        <FontAwesomeIcon
          data-testid="toggleIcon"
          icon={icon}
          onClick={handleToggleClick}
          style={{ cursor: 'pointer', marginLeft: '5' }}
        />
      </Text>
      {isHoursOpen && (
        <HoursNode />
      )}
    </div>
  );
}

StationHours.propTypes = {
  onChange: PropTypes.func,
  hours: StationHoursPropType,
};

StationHours.defaultProps = {
  onChange: noop,
  hours: {},
};
