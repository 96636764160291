import { generateStationSchema } from './generateStationSchema';

export function generateRegionalStationsSchema(stations) {
  if (!stations || !stations.length) {
    return null;
  }

  return {
    '@type': 'ItemList',
    numberOfItems: stations.length,
    itemListElement: stations.map(station => generateStationSchema(station)),
  };
}
