import PropTypes from 'prop-types';

import Reply from './reply';

export default PropTypes.shape({
  content: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.string,
  user: PropTypes.shape({
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  rating: PropTypes.number,
  replies: PropTypes.arrayOf(Reply),
});
