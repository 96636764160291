import { CountryIcon, Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { canonicalizeString } from '../../../lib/utils';
import { COUNTRY_CODES } from '../../constants';
import caProvinces from '../../constants/caProvinces';
import usStates from '../../constants/usStates';
import CountryPropTypes from '../../prop-types/country';
import DataGrid from '../DataGrid';
import styles from './RegionContainer.module.css';
import useGeoIpInfo from '../../../lib/hooks/useGeoIpInfo';

const cx = classnames.bind(styles);

const formatRegionItem = ({ name }) => ({
  key: name,
  displayText: name,
  url: canonicalizeString(`/gasprices/${name}`),
});

const COUNTRY_INFO = {
  [COUNTRY_CODES.USA]: {
    countryCode: COUNTRY_CODES.USA,
    code3Chars: 'USA',
    regionTerm: 'State',
    friendlyName: 'the USA',
    regions: usStates.map(formatRegionItem),
  },
  [COUNTRY_CODES.CANADA]: {
    countryCode: COUNTRY_CODES.CANADA,
    code3Chars: 'CAN',
    regionTerm: 'Province',
    friendlyName: 'Canada',
    regions: caProvinces.map(formatRegionItem),
  },
};

function getFlagHeader(country) {
  const { regionTerm, code3Chars } = COUNTRY_INFO[country];
  return (
    <Header as="h2" color="grey" className={cx('flagHeader')}>
      <CountryIcon countryCode={code3Chars} size="xl" />
      <Text className={cx('flagTitle')}>Choose a {regionTerm}</Text>
    </Header>
  );
}

function getHeader(country, isFlagInHeader) {
  return isFlagInHeader ? getFlagHeader(country) : `Find Cheap Gas Prices in ${COUNTRY_INFO[country].friendlyName}`;
}

export default function RegionContainer({ country, isFlagInHeader }) {
  const geoIp = useGeoIpInfo();
  const countryCode = country || geoIp.country;
  // ensure setting country info only for NAM country codes
  const countryInfo = countryCode === COUNTRY_CODES.CANADA ? COUNTRY_INFO[COUNTRY_CODES.CANADA] : COUNTRY_INFO[COUNTRY_CODES.USA];
  const { countryCode: resolvedCountryCode, regions } = countryInfo;

  const analytics = useAnalytics();
  const header = getHeader(resolvedCountryCode, isFlagInHeader);
  const columnProps = isFlagInHeader ? { mobile: 6, tablet: 4 } : { mobile: 6 };

  const handleItemClick = useCallback(({ displayText: regionName }) => {
    analytics.tagEvent({
      name: 'SearchList_StateLink',
      attributes: { State: regionName },
    });
  }, [analytics]);

  return (
    <DataGrid
      header={header}
      items={regions}
      onItemClick={handleItemClick}
      {...columnProps}
    />
  );
}

RegionContainer.propTypes = {
  country: CountryPropTypes,
  isFlagInHeader: PropTypes.bool,
};

RegionContainer.defaultProps = {
  country: undefined,
  isFlagInHeader: false,
};
