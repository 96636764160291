import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Dropdown, FlexGrid, Header, Loader, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import store from 'store';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import usePreferredFuelTypeID from '../../../lib/hooks/usePreferredFuelTypeID';
import getFuelOption from '../../../lib/utils/getFuelOption';
import { AUSTRALIA_COUNTRY_CODE, FUEL_OPTIONS } from '../../constants';
import FavoriteStations from '../FavoriteStations';
import styles from './ManageFavoriteStations.module.css';

const cx = classnames.bind(styles);

export const GET_FAVORITE_LIST = gql`
  query GetFavoriteStationLists($id: ID!) {
    favoriteStationList(id: $id) {
      displayName
      id
      isDefault
      name
      stations {
        address {
          country
          line1
          line2
          locality
          postalCode
          region
        }
        brands {
          brandId
          brandingType
          imageUrl
          name
        }
        enterprise
        fuels
        id
        name
        offers {
          discounts {
            grades
            highlight
            pwgbDiscount
            receiptDiscount
          }
          highlight
          id
          types
          use
        }
        payStatus {
          isPayAvailable
        }
        prices {
          cash {
            nickname
            postedTime
            price
            formattedPrice
          }
          credit {
            nickname
            postedTime
            price
            formattedPrice
          }
          fuelProduct
          longName
        }
        priceUnit
        ratingsCount
        starRating
      }
      stationIds
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation UpdateStationList($id: String!, $isDefault: Boolean!, $name: String!, $stationIds: [Int!]!) {
    updateStationList(id: $id, isDefault: $isDefault, name: $name, stationIds: $stationIds) {
      displayName
      id
      isDefault
      name
      stationIds
    }
  }
`;

export default function ManageFavoriteStations() {
  const { favoriteListId: selectedListId } = useParams();
  const analytics = useAnalytics();
  const fuel = usePreferredFuelTypeID();
  const [selectedFuelType, setSelectedFuelType] = useState(fuel);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const { data: fetchData, error: fetchError, loading: isFetching, refetch } = useQuery(GET_FAVORITE_LIST, {
    skip: !selectedListId,
    variables: {
      id: selectedListId,
    },
  });

  const activeList = fetchData?.favoriteStationList;
  const supportedStations = useMemo(
    () => (activeList?.stations || []).filter(station => station.address.country !== AUSTRALIA_COUNTRY_CODE),
    [activeList],
  );
  const hasAustraliaStations = useMemo(
    () => (activeList?.stations || []).length > supportedStations.length,
    [activeList, supportedStations],
  );

  const [updateStationList, { error: updateError, loading: isSaving }] = useMutation(UPDATE_LIST, {
    update(cache, { data }) {
      const listInfo = cache.readQuery({ query: GET_FAVORITE_LIST, variables: { id: selectedListId } }).favoriteStationList;
      const newOrderedStations = data.updateStationList.stationIds.map((id) => {
        const { __typename, ...rest } = listInfo.stations.find(station => station.id === id.toString()) || {};
        return rest;
      });
      cache.writeQuery({
        query: GET_FAVORITE_LIST,
        data: {
          favoriteStationList: {
            ...listInfo,
            stations: newOrderedStations,
          },
        },
        variables: {
          id: selectedListId,
        },
      });
    },
  });
  let action;
  if (fetchError) {
    action = 'retrieving';
  } else if (updateError) {
    action = 'updating';
  }
  const error = action && `An error occurred ${action} this station list. Please try again later.`;

  const handleStationsReordered = useCallback((movedFromIndex, movedToIndex) => {
    // displayName & stations need to be discarded from request when updating favorite list
    const { displayName, isDefault, stations, stationIds, ...listProps } = activeList;
    const activeStationIds = [...stationIds]; // stations is read-only from the query

    const swappedStationId = stationIds[movedFromIndex];
    activeStationIds.splice(movedFromIndex, 1);
    activeStationIds.splice(movedToIndex, 0, swappedStationId);

    updateStationList({
      variables: {
        ...listProps,
        isDefault: isDefault || false,
        stationIds: activeStationIds,
      },
    });
  }, [activeList, updateStationList]);

  const handleFuelTypeChange = useCallback(({ target }) => {
    const selectedLabel = getFuelOption(target.value).label;
    setSelectedFuelType(Number(target.value));
    store.set('lastFuelType', selectedLabel);
    analytics.identifyUser({ fuel_type: selectedLabel });
  }, [analytics]);

  const handlePricesPosted = useCallback(() => {
    analytics.identifyUser({ has_reported_prices: true });
    refetch();
  }, [analytics, refetch]);

  const handleSwitchMode = useCallback(() => {
    setIsInEditMode(toggle => !toggle);
  }, []);

  let content;
  if (isSaving || isFetching) {
    content = (
      <Loader size="lg">
        {isSaving ? 'Saving changes' : 'Loading your favorite stations'}...
      </Loader>
    );
  } else if (fetchError) {
    content = (
      <Text color="orange">{error}</Text>
    );
  } else {
    content = !!activeList && (
      <Fragment>
        {!!error && (
          <Fragment>
            <Text color="orange">{error}</Text>
            <br /><br />
          </Fragment>
        )}
        {hasAustraliaStations && (
          <Fragment>
            <Text color="orange">
              This list contains Australian stations that GasBuddy no longer supports, and are not shown in this list.
            </Text>
            <br /><br />
          </Fragment>
        )}
        <FavoriteStations
          fuelType={selectedFuelType}
          isEditable={isInEditMode}
          loading={isFetching}
          onPricesPosted={handlePricesPosted}
          onReorder={handleStationsReordered}
          stations={supportedStations}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FlexGrid container>
        <FlexGrid.Column desktop={7}>
          {!!activeList && <Header as="h1" className={cx('listHeader')}>{activeList.name}</Header>}
        </FlexGrid.Column>
        {!!supportedStations.length && (
          <Fragment>
            <FlexGrid.Column desktop={2} mobile={4} className={cx('editButtonColumn', 'topMargin')}>
              <Button primary fluid className={cx('editButton')} onClick={handleSwitchMode}>
                {isInEditMode ? 'Done' : 'Edit'}
              </Button>
            </FlexGrid.Column>
            <FlexGrid.Column desktop={3} mobile={8} className={cx('topMargin')}>
              <Dropdown
                id="fuelType"
                label="Select fuel type"
                options={FUEL_OPTIONS}
                defaultValue={selectedFuelType}
                onChange={handleFuelTypeChange}
              />
            </FlexGrid.Column>
          </Fragment>
        )}
      </FlexGrid>
      <br />
      <div className={cx('favoriteStationsContent')}>
        {content}
      </div>
    </Fragment>
  );
}
