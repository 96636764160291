import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Image } from '@gasbuddy/react-components';
import styles from './BadgeImage.module.css';

const cx = classnames.bind(styles);

export default function BadgeImage({
  large,
  clickable,
  ...rest
}) {
  return (
    <Image
      className={cx('image', { large }, { clickable })}
      {...rest}
    />
  );
}

BadgeImage.propTypes = {
  large: PropTypes.bool,
  clickable: PropTypes.bool,
};

BadgeImage.defaultProps = {
  large: false,
  clickable: false,
};
