import { useContext, useMemo } from 'react';
import FeaturesContext from '../../common/context/features';
import useParsedLocalStorage from './useParsedLocalStorage';

export default function useFeatureGatedSlashPricing() {
  const features = useContext(FeaturesContext);
  const [userEnabled] = useParsedLocalStorage('lastShowPayPrices', true);
  const featureEnabled = useMemo(() => !!features?.find(f => f.n === 'deal_alerts')?.p?.slashPricing, [features]);

  return userEnabled && featureEnabled;
}
