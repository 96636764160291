import { Header } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { canonicalizeString } from '../../../lib/utils';
import LinkLocationPropType from '../../prop-types/link-location';
import DataGrid from '../DataGrid';
import styles from './AreaCountyList.module.css';

const cx = classnames.bind(styles);

export default function AreaCountyList({ title, items }) {
  const header = <Header as="h2" className={cx('listHeader')} color="grey">{title}</Header>;
  const gridItems = items.map(({ displayText, regionCode, url }) => ({
    key: `${displayText}, ${regionCode}`,
    displayText,
    url: typeof url === 'string' ? canonicalizeString(url) : url,
    className: cx('areaItem'),
  }));

  return (
    <DataGrid
      className={cx('grid')}
      header={header}
      items={gridItems}
      mobile={6}
      tablet={4}
    />
  );
}

AreaCountyList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    url: PropTypes.oneOfType([
      PropTypes.string,
      LinkLocationPropType,
    ]).isRequired,
  })),
};

AreaCountyList.defaultProps = {
  title: undefined,
  items: [],
};
