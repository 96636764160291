import { useCallback } from 'react';
import PropTypes from 'prop-types';

export default function NoClickPropagation({
  as: Element,
  children,
  ...rest
}) {
  const preventClickPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Element {...rest} onClick={preventClickPropagation}>
      {children}
    </Element>
  );
}

NoClickPropagation.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

NoClickPropagation.defaultProps = {
  as: 'div',
  children: undefined,
};
