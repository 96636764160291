import { Link, Text } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Fragment, memo, useMemo } from 'react';
import styles from './DealAlertStatus.module.css';

const cx = classnames.bind(styles);

function StatusPrimaryText({
  dealAlertStation,
  didExpire,
  isPwGBMember,
  uploadReceiptExpiryTimestamp,
}) {
  const primaryText = useMemo(() => {
    if (!dealAlertStation) {
      return null;
    }

    const { id: stationId, name: stationName } = dealAlertStation;

    // Note: GetUpside deals will have an receipt expiry timestamp, dynamic discounts will not
    if (!isPwGBMember && uploadReceiptExpiryTimestamp) {
      if (didExpire) {
        return (
          <Fragment>
            If you completed your transaction before time ran out, you can upload your receipt before {uploadReceiptExpiryTimestamp} and claim GasBack.
          </Fragment>
        );
      }
      return (
        <Fragment>
          Complete your transaction at <Link to={`/station/${stationId}`}>{stationName}</Link> before time runs out and upload your receipt before {uploadReceiptExpiryTimestamp} to get your GasBack!
        </Fragment>
      );
    }

    if (isPwGBMember) {
      if (didExpire) {
        return uploadReceiptExpiryTimestamp
          ? (
            <Fragment>
              If you did not use your Pay with GasBuddy card before time ran out, you can upload your receipt
              before {uploadReceiptExpiryTimestamp} and claim GasBack.
            </Fragment>
          ) : (
            <Fragment>
              This deal has expired, but if you used your Pay with GasBuddy card before time ran out, you will still be able to claim your GasBack.
            </Fragment>
          );
      }

      return (
        <Fragment>
          Automatically apply the discount to your fill-up when you use your Pay with GasBuddy card at <Link to={`/station/${stationId}`}>{stationName}</Link> before time runs out!
        </Fragment>
      );
    }

    return null;
  }, [dealAlertStation, didExpire, isPwGBMember, uploadReceiptExpiryTimestamp]);

  if (!primaryText) {
    return null;
  }

  return (
    <Text as="p" className={cx('primaryText')} data-testid="primaryText">{primaryText}</Text>
  );
}

StatusPrimaryText.propTypes = {
  dealAlertStation: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  didExpire: PropTypes.bool,
  isPwGBMember: PropTypes.bool,
  uploadReceiptExpiryTimestamp: PropTypes.string,

};

StatusPrimaryText.defaultProps = {
  dealAlertStation: undefined,
  didExpire: false,
  isPwGBMember: false,
  uploadReceiptExpiryTimestamp: undefined,
};

export default memo(StatusPrimaryText);
