export default function createSortByProperty(property) {
  return function byProperty(objA, objB) {
    const propertyA = objA[property];
    const propertyB = objB[property];

    if (propertyA < propertyB) {
      return -1;
    }

    if (propertyA > propertyB) {
      return 1;
    }

    return 0;
  };
}
