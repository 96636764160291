import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { addZeroIfNeeded } from '../../../lib/utils/index';
import styles from './CountdownClock.module.css';

const cx = classnames.bind(styles);

const countdownIntervalInMs = 1000;

function getTimeUntilEnd(endDate) {
  const currentTime = new Date().getTime();
  const endDateTime = Date.parse(endDate);

  if (!endDateTime) {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      isZero: false,
    };
  }

  const secondsDifference = (endDateTime - currentTime) / countdownIntervalInMs;

  const hoursUntilEnd = Math.max(Math.floor(secondsDifference / (60 * 60)), 0);
  // ex: floor(3662 / 3600) = 1

  const minutesUntilEndDivisor = secondsDifference % (60 * 60); // ex: 3662 % 3600 = 62
  const minutesUntilEnd = Math.max(Math.floor(minutesUntilEndDivisor / 60), 0);
  // ex: floor(62 / 60) = 1

  const secondsUntilEnd = Math.max(Math.floor(minutesUntilEndDivisor % 60), 0);
  // ex: 62 % 60 = 2

  const isZero = hoursUntilEnd <= 0 && minutesUntilEnd <= 0 && secondsUntilEnd <= 0;

  const remainingTime = {
    hours: addZeroIfNeeded(hoursUntilEnd),
    minutes: addZeroIfNeeded(minutesUntilEnd),
    seconds: addZeroIfNeeded(secondsUntilEnd),
    isZero,
  };

  return remainingTime;
}
/**
 * A component that displays a countdown timer in a digital clock format from a specified endDate
 * (hh:mm:ss)
 *
 * @function CountdownClock
 */
export default function CountdownClock({ endDate, labelled, onEnd }) {
  const [remainingTime, setRemainingTime] = useState(getTimeUntilEnd(endDate));
  const timer = useRef();

  useEffect(() => {
    function continueCountdown() {
      const newRemainingTime = getTimeUntilEnd(endDate);

      setRemainingTime(newRemainingTime);

      if (newRemainingTime.isZero) {
        clearInterval(timer.current);
        onEnd();
      }
    }

    // if the endDate or onEnd changes, we need to clear our existing timer and start a new one
    clearInterval(timer.current);
    timer.current = setInterval(continueCountdown, countdownIntervalInMs);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [endDate, onEnd]);

  return (
    <Text as="p" className={cx('countdownContainer')} data-testid="countdownContainer">
      <span className={cx('clockTimer')}>
        <span className={cx('clockTimerValue')}>{remainingTime.hours}</span>
        &nbsp;:&nbsp;
        <span className={cx('clockTimerValue')}>{remainingTime.minutes}</span>
        &nbsp;:&nbsp;
        <span className={cx('clockTimerValue')}>{remainingTime.seconds}</span>
      </span>
      <br />
      {labelled
        && (
          <span className={cx('clockTimerLabel')}>
            HRS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MINS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SECS
          </span>
        )
      }
    </Text>
  );
}

CountdownClock.propTypes = {
  /** The desired date & time at which the countdown will hit 0 */
  endDate: PropTypes.string,
  /** Function executed when the countdown reaches 0 */
  onEnd: PropTypes.func,
  /** Whether or not the countdown should be labelled */
  labelled: PropTypes.bool,
};

CountdownClock.defaultProps = {
  endDate: new Date().toString(),
  onEnd: () => { },
  labelled: true,
};
