import usStates from '../../common/constants/usStates';
import caProvinces from '../../common/constants/caProvinces';

/**
 * Retrieves a region object by a passed in regionCode and countryCode
 * @param {*} regionCode The code value for the region
 * @param {*} countryCode The country code value the region resides in.
 * Note: some countries share region codes, which is why this parameter is necessary (ex: WA is Washington in the USA, but is West Australia in Australia)
 */
export default function getRegionByCodes(regionCode, countryCode) {
  const allRegions = [...usStates, ...caProvinces];
  return allRegions.find(r => r.abbr === regionCode && r.countryCode === countryCode);
}
