import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Image, Loader, Panel, Text } from '@gasbuddy/react-components';
import AmenityPropType from '../../prop-types/amenity';

import styles from './AmenitiesList.module.css';

const cx = classnames.bind(styles);
export default function AmenitiesList({ amenities, expanded, loading }) {
  if (!loading && !amenities.length) {
    return (
      <Text as="p" color="midnight" italic>No amenities found.</Text>
    );
  }

  return (
    <div>
      <Panel className={cx('amenitiesPanel')} color="white" bordered={expanded} rounded={expanded} padded={expanded}>
        {loading
          ? <Loader>Loading amenities for this station...</Loader>
          : (
            <div className={cx('amenityItemRow')}>
              {amenities.map(({ imageUrl, name }) => (
                <div key={name} className={cx('amenityItem')}>
                  <Image
                    alt=""
                    className={cx('amenityIcon')}
                    src={imageUrl}
                  />
                  <Text>{name}</Text>
                </div>
              ))}
            </div>
          )
        }
      </Panel>
    </div>
  );
}

AmenitiesList.propTypes = {
  /** An array of amenities to display. Not needed if have stationId prop */
  amenities: PropTypes.arrayOf(AmenityPropType),
  /** Whether or not the list container is padded and has a header */
  expanded: PropTypes.bool,
  /** If amenities are being loaded */
  loading: PropTypes.bool,
};

AmenitiesList.defaultProps = {
  amenities: [],
  expanded: true,
  loading: false,
};
