import { Actions, Button, Form, Image, Modal, Text, Tray } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import useDealAlert from '../../../lib/hooks/useDealAlert';
import { formatLocaleTime, formatTimestamp } from '../../../lib/utils/formatDateTime';
import CountdownClock from '../CountdownClock';
import ZoomSlider from '../ZoomSlider';
import styles from './DealAlertStatus.module.css';
import StatusPrimaryText from './StatusPrimaryText';

const cx = classnames.bind(styles);

const megabytes = n => n * 1000000;

function canvasToBlob(canvas, options = {}) {
  const { mimeType, quality } = options;

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob(resolve, mimeType, quality);
    } catch (error) {
      reject(error);
    }
  });
}

async function compress(canvas, maxSize = 1, mimeType) {
  const maxBytes = megabytes(maxSize);
  let quality = 1;
  let blob = await canvasToBlob(canvas, { mimeType, quality });

  while ((blob.size > maxBytes) && (quality > 0.1)) {
    quality -= 0.05;
    blob = canvasToBlob(canvas, { quality });
  }

  return blob;
}

function DealAlertStatus({ className, isPwGBMember }) {
  const {
    dealAlert,
    isUploading: uploading,
    uploadReceipt,
    didUploadReceipt: uploaded,
  } = useDealAlert();
  const expires = dealAlert?.transactBy;
  const uploadReceiptExpiry = dealAlert?.submitBy;
  const fileInput = useRef();
  const receiptForm = useRef();
  const editor = useRef();
  const [receipt, setReceipt] = useState();
  const [userSaidNo, setUserSaidNo] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [didExpire, setDidExpire] = useState(expires && (new Date(expires) < new Date()));

  // Update messaging if expiry passes while user is on the page
  const handleExpiration = useCallback(() => {
    setDidExpire(true);
  }, []);

  const sayNo = useCallback(() => {
    setUserSaidNo(true);
  }, []);

  const handleUploadClick = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, []);

  const handleReceipt = useCallback(({ target }) => {
    const { files: [file] } = target;

    // Only open the modal if a file was selected
    if (file) {
      setReceipt(file);
    }
  }, []);

  const handleScale = useCallback((e) => {
    setScale(parseFloat(e.target.value));
  }, []);

  // Mark deal alert as expired appropriately if deal alert data changes
  useEffect(() => {
    setDidExpire(expires && (new Date(expires) < new Date()));
  }, [expires]);

  const questions = useMemo(() => {
    const list = [];

    if (!dealAlert) {
      return [];
    }

    const { claimedAt, transactBy } = dealAlert;
    const startTime = formatLocaleTime(claimedAt);
    const endTime = formatLocaleTime(transactBy);

    list.push('Did you pay with a credit or debit card?');
    list.push('Is the card type and last 4 digits of your credit or debit card shown?');
    list.push('Is the address of the station pictured?');
    list.push('Does this receipt show the purchase date and time?');
    list.push(`Did you make your purchase between ${startTime} - ${endTime}?`);
    list.push('Is the fuel type for your purchase shown?');
    list.push('Is the total gallons purchased shown?');
    list.push('Is the purchase total amount pictured?');

    return list;
  }, [dealAlert]);

  const submitReceipt = useCallback(() => {
    const MAX_SIZE = 0.5;

    if (receipt.size < (megabytes(MAX_SIZE))) {
      uploadReceipt({
        variables: {
          offerId: dealAlert?.offerId,
          receipt,
        },
      });
    } else {
      compress(editor.current.getImage(), MAX_SIZE, receipt.type)
        .then((compressedReceipt) => {
          uploadReceipt({
            variables: {
              offerId: dealAlert?.offerId,
              receipt: new File([compressedReceipt], receipt.name, { type: compressedReceipt.type }),
            },
          });
        });
    }
  }, [dealAlert, receipt, uploadReceipt]);

  const resetReceiptForm = useCallback(() => {
    setReceipt(undefined);
    setUserSaidNo(false);
    setQuestionIndex(0);
    receiptForm.current.reset();
  }, []);

  const handleModalClose = useCallback(() => {
    resetReceiptForm();
  }, [resetReceiptForm]);

  const progressQuestion = useCallback(() => {
    const nextIndex = questionIndex + 1;

    if (questionIndex === questions.length - 1) {
      submitReceipt();
    } else {
      setQuestionIndex(nextIndex);
    }
  }, [questions.length, questionIndex, submitReceipt]);

  const handleDifferentReceipt = useCallback((e) => {
    resetReceiptForm();
    handleUploadClick(e);
  }, [handleUploadClick, resetReceiptForm]);

  const getModalContent = useCallback(({ close }) => {
    if (uploaded) {
      return (
        <div>
          <Text as="p" bold fluid size="lg">Your Deal Alert receipt has been successfully uploaded!</Text>
          <Image
            alt="Check Circle"
            src="//static.gasbuddy.com/web/consumer/vehicles/Checkmark.svg"
            className={cx('checkCircle')}
          />
          <Text as="p">
            Your GasBack is on the way!
            You&apos;ll get your GasBack in 24 - 48 hours.
            In some cases it can take up to 10 business days to process.
            We&apos;ll let you know once it does, either way!
          </Text>
          <br />
          <Button onClick={close} primary wide>Done</Button>
        </div>
      );
    }

    if (userSaidNo) {
      if (questionIndex === 0) {
        return (
          <div>
            <Text as="p" bold fluid size="lg">Unable to claim discounts!</Text>
            <Text as="p">Sorry, offers are only valid for credit or debit card purchases.</Text>
            <Text as="p">For more info, please read the deal alert offer details and visit our support article.</Text>
            <br />
            <Button onClick={close} primary wide>Close</Button>
            <Button link onClick={handleDifferentReceipt}>Use Different Receipt</Button>
          </div>
        );
      }

      return (
        <div>
          <Text as="p" bold fluid size="lg">It looks like some things are missing.</Text>
          <Text as="p">We can still review your receipt and try to award you with GasBack.</Text>
          <br />
          <Button onClick={submitReceipt} primary wide>Upload Receipt Now</Button>
          <Button link onClick={handleDifferentReceipt}>Use Different Receipt</Button>
        </div>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <AvatarEditor
          image={receipt}
          height={300}
          ref={editor}
          rotate={0}
          scale={scale}
          width={150}
        />
        <ZoomSlider onChange={handleScale} />
        <br />
        <div>
          {questions.map((q, i) => (
            <div key={q} className={cx('step', { done: i <= questionIndex })} />
          ))}
        </div>
        <Text fluid>{questions[questionIndex]}</Text>
        <br />
        <Button loading={uploading} onClick={progressQuestion} primary wide>Yes</Button>
        <Button onClick={sayNo} secondary wide>No</Button>
      </div>
    );
  }, [handleDifferentReceipt, handleScale, progressQuestion, questionIndex, questions, receipt, sayNo, scale, submitReceipt, uploaded, uploading, userSaidNo]);

  const uploadReceiptExpiryTimestamp = useMemo(() => uploadReceiptExpiry && formatTimestamp(uploadReceiptExpiry), [uploadReceiptExpiry]);

  if (!dealAlert) {
    return null;
  }

  return (
    <Tray className={cx('tray')} isOpen mobile={false}>
      <div className={cx('dealStatus', className)}>
        <Modal
          content={getModalContent}
          forceIsShowing={!!receipt}
          onClose={handleModalClose}
          size="sm"
        />
        <CountdownClock endDate={expires} onEnd={handleExpiration} />
        <StatusPrimaryText
          dealAlertStation={dealAlert?.station}
          didExpire={didExpire}
          isPwGBMember={isPwGBMember}
          uploadReceiptExpiryTimestamp={uploadReceiptExpiryTimestamp}
        />
        {uploadReceiptExpiry && (
          <Fragment>
            <Form ref={receiptForm}>
              <input
                accept="image/png, image/jpeg"
                onChange={handleReceipt}
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
              />
            </Form>
            <Actions.Button
              className={cx({ deal: !isPwGBMember })}
              desktop={6}
              onClick={handleUploadClick}
              secondary={isPwGBMember}
            >
              {isPwGBMember ? 'I didn\'t use my card' : 'Upload Receipt'}
            </Actions.Button>
          </Fragment>
        )}
      </div>
    </Tray>
  );
}

DealAlertStatus.propTypes = {
  className: PropTypes.string,
  isPwGBMember: PropTypes.bool,
};

DealAlertStatus.defaultProps = {
  className: undefined,
  isPwGBMember: false,
};

export default DealAlertStatus;
