import store from 'store';
import { FUEL_TYPES } from '../../common/constants';

/**
 * Attempts to identify a user's preferred fuel type by checking for a setting in local storage and
 * defaulting to Regular. The returned value is the fuel type.
 * @returns string A string representing the fuel type
 */
export default function usePreferredFuelType() {
  return store.get('lastFuelType') || FUEL_TYPES.REGULAR;
}
