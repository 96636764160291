import PropTypes from 'prop-types';

export default function Schema({ value }) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
        __html: JSON.stringify(value),
      }}
    />
  );
}

Schema.propTypes = {
  value: PropTypes.shape({
    '@context': PropTypes.string.isRequired,
    '@graph': PropTypes.arrayOf(
      PropTypes.shape({
        '@type': PropTypes.string.isRequired,
        '@id': PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        inLanguage: PropTypes.string,
        availability: PropTypes.string,
        author: PropTypes.string,
        logo: PropTypes.shape({
          '@type': PropTypes.string,
          '@id': PropTypes.string,
          inLanguage: PropTypes.string,
          url: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
          caption: PropTypes.string,
        }),
        image: PropTypes.shape({
          '@id': PropTypes.string,
        }),
        about: PropTypes.shape({
          '@id': PropTypes.string,
        }),
        isPartOf: PropTypes.shape({
          '@id': PropTypes.string,
        }),
        offers: PropTypes.shape({
          '@type': PropTypes.string,
          url: PropTypes.string,
          availability: PropTypes.string,
          seller: PropTypes.shape({
            '@type': PropTypes.string,
            name: PropTypes.string,
          }),
        }),
      }),
    ).isRequired,
  }).isRequired,
};
