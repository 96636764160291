import { COUNTRY_CODES } from '../../common/constants';

export default function getLocale(countryCode) {
  switch (countryCode) {
    case COUNTRY_CODES.CANADA:
      return 'en-CA';
    default:
      return 'en-US';
  }
}
