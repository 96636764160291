import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './PhoneLink.module.css';
import { formatPhoneNumber } from '../../../lib/utils';

const cx = classnames.bind(styles);

export const PhoneLink = ({ number }) => {
  const formatted = formatPhoneNumber(number);
  return <a href={`tel:${formatted}`} className={cx('size', 'blue')}>{formatted}</a>;
};

PhoneLink.propTypes = {
  number: PropTypes.string.isRequired,
};
