import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import useStationPayAvailability from '../../../lib/hooks/useStationPayAvailability';
import { FUEL_TYPES } from '../../constants';
import StationPropType from '../../prop-types/station';
import BadgeAds from '../BadgeAds';
import EnterpriseBadge from '../EnterpriseBadge';
import NoClickPropagation from '../NoClickPropagation';
import OutageStatusBadges from '../OutageStatusBadges';
import StarRating from '../StarRating';
import StationBrandings from '../StationBrandings';
import StationDisplayName from '../StationInfoBox/StationDisplayName';
import styles from './StationDisplay.module.css';

const cx = classnames.bind(styles);

export default function StationDisplay({
  showBadges,
  showBrands,
  showRating,
  station,
}) {
  const {
    address,
    badges,
    brands = [],
    distance,
    emergencyStatus,
    enterprise: isEnterprise,
    fuels = [],
    hasActiveOutage,
    id,
    name,
    starRating,
    ratingsCount,
  } = station || {};

  const sellsDiesel = useMemo(() => fuels.some(fuel => fuel === FUEL_TYPES.DIESEL.toLowerCase()), [fuels]);
  const isPayAvailable = useStationPayAvailability(station);

  return (
    <Fragment>
      {showBrands && (
        <div className={cx('logoColumn')}>
          <StationBrandings
            brands={brands}
            stationName={name}
          />
        </div>
      )}

      <div className={cx('mainInfoColumn')}>
        <StationDisplayName
          as="h3"
          brands={brands}
          stationName={name}
          headerClass={cx('stationNameHeader')}
          subheaderClass={cx('stationNameSubHeader')}
          to={`/station/${id}`}
          headerBadge={(
            <Fragment>
              {isEnterprise && (
                <NoClickPropagation as="span">
                  <EnterpriseBadge />
                </NoClickPropagation>
              )}
              {!!distance && (
                <div className={cx('distanceLabel')}>
                  <div className={cx('distanceContainer')}>
                    {distance}
                  </div>
                </div>
              )}
            </Fragment>
          )}
        />
        {showRating && (
          <div className={cx('ratingContainer')}>
            <StarRating
              name={`station${id}Rating`}
              rating={starRating}
              size="small"
            />
            &nbsp;
            <Text className={cx('numberOfReviews')}>
              {ratingsCount || 0}
            </Text>
          </div>
        )}
        {address && (
          <div className={cx('address')}>
            {address.line1}
            <br />
            {address.locality && address.region && `${address.locality}, ${address.region}`}
          </div>
        )}

        {showBadges && hasActiveOutage && (
          <div className={cx('emergencyStatus')}>
            <OutageStatusBadges
              powerReport={emergencyStatus?.hasPower && {
                status: emergencyStatus.hasPower.reportStatus,
                username: emergencyStatus.hasPower.nickname,
                timestamp: emergencyStatus.hasPower.updateDate,
              }}
              gasReport={emergencyStatus?.hasGas && {
                status: emergencyStatus.hasGas.reportStatus,
                username: emergencyStatus.hasGas.nickname,
                timestamp: emergencyStatus.hasGas.updateDate,
              }}
              dieselReport={emergencyStatus?.hasDiesel && {
                status: emergencyStatus.hasDiesel.reportStatus,
                username: emergencyStatus.hasDiesel.nickname,
                timestamp: emergencyStatus.hasDiesel.updateDate,
              }}
              sellsDiesel={sellsDiesel}
              iconsOnly
            />
          </div>
        )}

        {showBadges && !hasActiveOutage && (
          <BadgeAds
            badges={badges}
            isPayAvailable={isPayAvailable}
          />
        )}
      </div>
    </Fragment>
  );
}

StationDisplay.propTypes = {
  showBrands: PropTypes.bool,
  showBadges: PropTypes.bool,
  showRating: PropTypes.bool,
  station: StationPropType,
};

StationDisplay.defaultProps = {
  showBrands: false,
  showBadges: false,
  showRating: true,
  station: {},
};
