import { COUNTRY_CODES } from '.';
import { canonicalizeString } from '../../lib/utils';

const caProvinces = [
  { name: 'Alberta', abbr: 'AB' },
  { name: 'British Columbia', abbr: 'BC' },
  { name: 'Manitoba', abbr: 'MB' },
  { name: 'New Brunswick', abbr: 'NB' },
  { name: 'Newfoundland', abbr: 'NL' },
  { name: 'Nova Scotia', abbr: 'NS' },
  { name: 'Northwest Territories', abbr: 'NT' },
  { name: 'Nunavut', abbr: 'NU' },
  { name: 'Ontario', abbr: 'ON' },
  { name: 'PEI', abbr: 'PE' },
  { name: 'Quebec', abbr: 'QC' },
  { name: 'Saskatchewan', abbr: 'SK' },
  { name: 'Yukon', abbr: 'YT' },
];

export default caProvinces.map(region => ({
  ...region,
  canonical: canonicalizeString(region.name),
  countryCode: COUNTRY_CODES.CANADA,
}));
