import { FUEL_POI_VALUE_TO_NAME } from '../../common/constants';
import { getStationBrand } from './station';

const IMAGE_SIZE = 256;

export function getStationDescription(station) {
  const { address, amenities = [], fuels = [], name, starRating: rating } = station;
  const reviews = station.reviews?.results || [];
  let stationDescription = `${name} in ${address.locality}, ${address.region}.`;

  const fuelsDescription = fuels.map(fuel => FUEL_POI_VALUE_TO_NAME[fuel]).join(', ');
  stationDescription += ` Carries ${fuelsDescription}.`;

  if (amenities.length) {
    stationDescription += ` Has ${amenities.reduce(
      (prev, curr) => ([...prev, curr.name]), [],
    ).join(', ')}.`;
  }

  stationDescription += ' Check current gas prices and read customer reviews.';

  // Our rating is valid as long as we have at least 1 review and our rating is properly defined
  if (rating !== undefined && reviews.length) {
    stationDescription += ` Rated ${rating} out of 5 stars.`;
  }

  return stationDescription;
}

export function generateStationSchema(station) {
  const reviews = station.reviews?.results || [];
  const stationBrandId = getStationBrand(station.brands)?.brandId || 0;
  const schema = {
    '@type': 'GasStation',
    name: station.name,
    url: `https://www.gasbuddy.com/station/${station.id}`,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: station.latitude,
      longitude: station.longitude,
    },
    address: {
      streetAddress: station.address.line1,
      addressLocality: station.address.locality,
      addressRegion: station.address.region,
      postalCode: station.address.postalCode,
      country: station.address.country,
      telephone: station.phone,
    },
    image: {
      '@id': `https://images.gasbuddy.com/di/b/1/${stationBrandId}.png?w=${IMAGE_SIZE}`,
    },
    description: getStationDescription(station),
    openingHours: station.hours?.openingHours,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: station.starRating || 0,
      ratingCount: station.ratingsCount || 0,
      reviewCount: reviews?.count || undefined, // reviewCount must be positive, so make it undefined if it isn't
      bestRating: 5,
      worstRating: 1,
    },
    review: reviews.map(review => (
      {
        '@context': 'http://schema.org',
        '@type': 'Review',
        datePublished: review.reviewDate,
        author: {
          '@type': 'Person',
          name: review.memberId || 'Unknown',
          image: review.profileImageUrl,
        },
        reviewBody: review.review,
      }
    )),
  };

  // ratingCount must be positive, so if we don't have one, or it is 0, do not report an aggregateRating or review for this station
  if (schema.aggregateRating.ratingCount < 1) {
    delete schema.aggregateRating;
    delete schema.review;
  }

  return schema;
}
