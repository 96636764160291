import PropTypes from 'prop-types';
import { FUEL_POI_VALUE_TO_NAME } from '../constants';
import AddressProps from './address';
import BrandProps from './brand';

const Station = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numRatings: PropTypes.number,
  enterprise: PropTypes.bool,
  address: AddressProps,
  payStatus: PropTypes.shape({
    isPayAvailable: PropTypes.bool,
  }),
  phone: PropTypes.string,
});

export const MSLStation = PropTypes.shape({
  address: AddressProps,
  alias: PropTypes.string,
  amenities: PropTypes.arrayOf(PropTypes.string),
  brand: BrandProps,
  fuels: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FUEL_POI_VALUE_TO_NAME))),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  openStatus: PropTypes.string,
  phone: PropTypes.string,
});

export default Station;
