import classnames from 'classnames/bind';
import hdate from 'human-date';
import PropTypes from 'prop-types';
import { getMemberPriceStampInfoFromMemberName } from '../../../lib/utils';
import styles from './ReportedBy.module.css';

const cx = classnames.bind(styles);

export default function ReportedBy({ time, username }) {
  const { iconPath, linkUrl } = getMemberPriceStampInfoFromMemberName(username);
  const UserElement = username ? 'a' : 'span';

  return (
    <div>
      <span className={cx('user')}>
        <UserElement className={cx('memberLink')} href={username && linkUrl}>
          <img src={iconPath} alt="Price Reporter Icon" />
          &nbsp;
          {username || 'No Report'}
        </UserElement>
      </span>
      <span className={cx('postedTime')}>
        {time ? hdate.relativeTime(time) : 'N/A'}
      </span>
    </div>
  );
}

ReportedBy.propTypes = {
  time: PropTypes.string,
  username: PropTypes.string,
};

ReportedBy.defaultProps = {
  time: undefined,
  username: undefined,
};
