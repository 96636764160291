import PropTypes from 'prop-types';
import isSizeMapping from '../../lib/utils/isSizeMappingProp';

export const AdSizesPropType = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])));

function SizeMappingPropType(props, propName) {
  const { [propName]: value } = props;

  if (!isSizeMapping(value)) {
    return new Error(`${propName} needs to be [[dimensions, [...sizes]], ...]`);
  }

  return null;
}

export const AdParams = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
});

export const AdSizeMappingPropType = PropTypes.arrayOf(SizeMappingPropType);
